import React, { useEffect, useState, useRef } from 'react'
import classes from '../../assets/styles/clockIn.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import TableComponent from '../../components/Global Components/DataTable'
import TableFilter from '../../components/Global Components/TableFilter';
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import { DisputeViewer } from '../../components/Modal/disputeViewer'
import { Button, Col, Row, Card, CardBody, CardHeader } from 'reactstrap'
import { Link } from 'react-router-dom'
import { get } from '../../server';
import { ATTENDANCE } from '../../utils/constants/apiUrl';
import DatePicker, { DateObject } from "react-multi-date-picker";
import { BiRefresh } from 'react-icons/bi';
import { OvertimeViewer } from '../../components/Modal/OvertimeViewer';

const App = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const [date, setDate] = useState([
        new DateObject().toFirstOfWeek(),
        new DateObject().toLastOfWeek(),
    ])
    const [data, setData] = useState()
    const [weekData, setWeekData] = useState()
    const [Data, setDATA] = useState()
    const [query, setQuery] = useState("")
    const [filteredData, setFilteredData] = useState()
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [overtimes, setOvertimes] = useState()
    const rowData = useRef()
    const [modal, setModal] = useState()

    async function loadClocks() {
        let { status, data } = await get(
            ATTENDANCE + "/clockInOut/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            const startDate = new Date(date[0]).toISOString().slice(0, 10)
            const endDate = new Date(date[1]).toISOString().slice(0, 10)
            const filteredData = data.filter((record) => {
                const recordDate = new Date(record.date).toISOString().slice(0, 10)
                return recordDate >= startDate && recordDate <= endDate;
            });
            const report = calculateWeeklyReport(filteredData)
            setData(data)
            setDATA(report)
            setWeekData(report)
        }
    }

    async function loadOvertimeRequests() {
        let { status, data } = await get(
            ATTENDANCE + "/clockInOut/overtime/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) setOvertimes(data)
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadClocks()
        loadOvertimeRequests()
    }, [])

    function calculateWeeklyReport(data) {
        const employeeHours = {};

        for (const record of data) {
            const employeeId = record.employeeId;
            const hours = record.hours !== null ? record.hours : 0;
            const organizationId = record.organizationId;
            const weekNumber = getWeekNumber(record.date);

            if (employeeId in employeeHours) {
                employeeHours[employeeId].totalHours += hours;
            } else {
                employeeHours[employeeId] = {
                    organizationId: organizationId,
                    employee: record.Employee,
                    organization: record.Organization,
                    totalHours: hours,
                    weekNumber: weekNumber
                };
            }
        }

        const weeklyReport = [];

        for (const [employeeId, { organizationId, employee, organization, totalHours, weekNumber }] of Object.entries(employeeHours)) {
            weeklyReport.push({
                organizationId: organizationId,
                employeeId: Number(employeeId),
                employee: employee,
                organization: organization,
                totalHours: totalHours,
                weekNumber: weekNumber
            });
        }

        return weeklyReport;
    }

    function getWeekNumber(date) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        const day = d.getDay();
        const startOfWeek = new Date(d.getTime() - (day * 24 * 60 * 60 * 1000));
        startOfWeek.setHours(0, 0, 0, 0);
        const yearStart = new Date(startOfWeek.getFullYear(), 0, 1);
        const weekNumber = Math.ceil((((startOfWeek - yearStart) / 86400000) + 1) / 7);
        return weekNumber;
    }



    function weekSelect(week) {
        const startDate = new Date(week[0]).toISOString().slice(0, 10)
        const endDate = new Date(week[1]).toISOString().slice(0, 10)
        const filteredData = data.filter((record) => {
            const recordDate = new Date(record.date).toISOString().slice(0, 10)
            return recordDate >= startDate && recordDate <= endDate;
        });
        const report = calculateWeeklyReport(filteredData)
        setWeekData(report)
        setDATA(report)
    }

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.employee?.User?.firstName.toLowerCase().includes(query) || dat.employee?.User?.lastName.toLowerCase().includes(query)))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            if (query)
                setFilteredData(Data?.filter(dat => dat.employee?.User?.firstName.toLowerCase().includes(query) || dat.employee?.User?.lastName.toLowerCase().includes(query)))
        }
        else {
            let filtered
            if (typeof date === "object")
                filtered = weekData?.filter(data => data.organizationId === selectedOptions?.value)
            else
                filtered = weekData?.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(Data?.filter(dat => dat.employee?.User?.firstName.toLowerCase().includes(query) || dat.employee?.User?.lastName.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    const columns = [
        {
            name: "ID",
            selector: row => row.employeeId,
            sortable: true,
            center: true,
        },
        {
            name: 'Name',
            selector: row => row?.employee?.User?.firstName,
            sortable: true,
            cell: (row) => <div
            >
                {row?.employee?.User?.firstName} {row?.employee?.User?.lastName}
            </div >
        },
        {
            name: "Organization",
            selector: row => row?.organization?.name,
            sortable: true,
            omit: loggedUser?.user?.userType === "super_admin" ? false : true
        },
        {
            name: "Total Hours",
            selector: row => row?.totalHours,
            sortable: true,
            center: true
        },
        {
            name: "Action",
            center: true,
            cell: row => {
                const selected = overtimes.find(data => data.weekNumber === row.weekNumber && data.employeeId === row.employeeId)
                if (selected)
                    return (
                        <Button
                            className={`btn-simple ${classes.disputeButton}`}
                            color="google"
                            onClick={() => handleAction(selected, row)}
                        >
                            Overtime Request
                        </Button>)
            }
        }
    ]


    function handleAction(selected, row) {
        rowData.current = { ...selected, totalHours: row.totalHours }
        toggleModal()
    }

    const toggleModal = () => {
        setModal(!modal);
    };

    useEffect(() => {
        if (!modal) {
            loadClocks()
            loadOvertimeRequests()
        }
    }, [modal])

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Clock In & Out Report</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/home">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Clock In & Out Panel</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <Button color="success" className='create' onClick={() => loadClocks()}>
                            <BiRefresh className='buttonIcons' />
                            Refresh
                        </Button>
                        <TableFilter
                            query={query}
                            setQuery={setQuery}
                        />
                        {loggedUser?.user?.userType === "super_admin" && <Select
                            options={organizationOptions}
                            value={organization}
                            isClearable={true}
                            onChange={handleSelectChange}
                            placeholder="Select Organization"
                            className="inlineDropdown"
                        />}
                        <DatePicker
                            value={date}
                            placeholder="Select Week"
                            inputClass={`form-control ${classes.filter}`}
                            onChange={(e) => {
                                setDate(e)
                                weekSelect(e)
                            }}
                            calendarPosition="bottom-right"
                            format="DD MMMM YYYY"
                            weekPicker
                            displayWeekNumbers
                        />
                        <TableComponent
                            columns={columns}
                            Data={Data}
                            filteredData={filteredData}
                            query={query}
                            allcheck={false}
                        />

                        <OvertimeViewer
                            modal={modal}
                            toggleModal={toggleModal}
                            loggedUser={loggedUser}
                            data={rowData.current}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}
export default App