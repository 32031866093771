import React, { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import classes from '../../assets/styles/clockIn.module.css'
import { Modal, ModalBody, Button } from 'reactstrap'
import { post } from '../../server'
import { ATTENDANCE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import validateSchema from '../../helpers/ValidateSchema'
import OvertimeRequestSchema from '../../utils/SchemasValidation/OvertimeRequestSchema'

const emptyObj = {
    detail: ""
}

export const Overtime = ({ modal, toggleModal, loggedUser, data }) => {
    const [overtimeData, setOvertimeData] = useState(emptyObj)
    function closeModal() {
        toggleModal()
        setOvertimeData(emptyObj)
    }

    const handleChange = (e) => {
        setOvertimeData({ detail: e.target.value, week_number: data.WeekNumber, year: data.Year })
    }

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let Errors = validateSchema(overtimeData, OvertimeRequestSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    async function handleSubmit() {
        if (!validateForm()) return;
        const { status, message } = await post(
            ATTENDANCE + "/clockInOut/overtime/apply",
            overtimeData,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200) {
                closeModal()
            }
        }
    }

    return <>
        <Modal isOpen={modal} toggle={toggleModal}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <AiOutlineCloseCircle className="tim-icons" />
                </button>
                <h4 className="title title-up">Overtime Request</h4>
            </div>
            <ModalBody className="text-center">
                <div className={classes.container}>
                    <label className={classes.labels}>Week</label>
                    <input
                        value={data?.WeekNumber}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Month</label>
                    <input
                        value={data?.Month + ", " + data?.Year}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Total Hours</label>
                    <input
                        value={data?.TotalHours}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Details</label>
                    <div>
                        <textarea
                            value={overtimeData.detail}
                            className={errors?.detail ? `${classes.Date} errorInput` : classes.Date}
                            onChange={handleChange}
                            placeholder='Details'
                        />
                        <p className="errorText">{errors?.detail || ""}</p>
                    </div>
                </div>
            </ModalBody>
            <div className="modal-footer">
                <Button
                    color="danger"
                    type="button"
                    onClick={closeModal}
                >
                    Close
                </Button>
                <Button
                    color="info"
                    type="button"
                    onClick={handleSubmit}
                    style={{ marginLeft: '10px' }}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    </>
}