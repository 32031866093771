import { get } from "../../server";
import { DEPARTMENT, EMPLOYEE, ORGANIZATION, POSITION, ROLE } from "../../utils/constants/apiUrl";

export async function loadOrganization(loggedUser, setOrganizationOptions) {
    if (loggedUser?.user.userType === "super_admin") {
        let { status, data } = await get(
            ORGANIZATION + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const options = data?.map(({ id, name }) => ({
                value: id,
                label: name
            }))
            if (options) {
                setOrganizationOptions(options)
                // setOrganization(options[0])
                // setPermissions(permissions.filter(data => data.organization_id === options[0].value))
            }
        }
    }
}

export async function loadDepartment(loggedUser, setDepartmentOptions) {
    let { status, data } = await get(
        DEPARTMENT + "/listing",
        Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
    );
    if (status === 200) {
        const values = data.map((department) => ({
            value: department.id,
            label: department.name,
            organization_id: department.organizationId
        }))
        setDepartmentOptions(values)
    }
}

export async function loadPositions(loggedUser, setPositionOptions) {
    let { status, data } = await get(
        POSITION + "/listing",
        Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
    )
    if (status === 200) {
        const values = data.map((positions) => ({
            value: positions.id,
            label: positions.name,
            hierarchyLevel: positions.hierarchyLevel,
            organization_id: positions.organizationId
        }))
        setPositionOptions(values)
    }
}

export async function loadOrganizationEmployees(loggedUser, setOrganizationEmployees) {
    let { status, data } = await get(
        EMPLOYEE + "/listing",
        Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
    )
    if (status === 200) {
        const updatedData = data.map(employees => {
            const name = employees.User?.firstName + " " + employees.User?.lastName + " - " + employees.Position?.name
            return ({
                value: employees.id,
                label: name,
                hierarchyLevel: employees.Position?.hierarchyLevel,
                organization_id: employees.organizationId
            })
        })
        setOrganizationEmployees(updatedData)
    }
}

export async function loadPermissions(loggedUser, setPermissionOptions) {
    let { status, data } = await get(
        ROLE + "/listing",
        Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
    )
    if (status === 200) {
        const updatedData = data.map(permissions => ({
            value: permissions.id,
            label: permissions.name,
            organization_id: permissions.organizationId
        }))
        setPermissionOptions(updatedData)
    }
}