const Joi = require("joi");
const EmployeeEducationSchema = Joi.object({
    id: Joi.allow(null),
    //Education

    institute_name: Joi.string().min(1).max(50).required().messages({
        "string.empty": `Please enter Institute Name`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 50 characters!`,
    }),

    degree_title: Joi.string().min(1).max(50).required().messages({
        "string.empty": `Please enter Degree Title`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 50 characters!`,
    }),

    degree_type: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Degree Type`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),

    session_from: Joi.number().greater(1990).max(Joi.ref('session_to')).required().messages({
        "number.empty": `Please Enter Year`,
        "number.min": `Invalid Year!`,
        "date.max": `Starting Year cannot be after Ending Year`,
        "number.max": `Invalid Year!`,
    }),

    session_to: Joi.number().greater(1990).required().messages({
        "number.empty": `Please Enter Year`,
        "number.min": `Invalid Year!`,
        "number.max": `Invalid Year!`,
    }),

});

export default EmployeeEducationSchema;
