import React, { useEffect, useState } from 'react'
import defaultPicture from '../../assets/images/defaultProfile.png'
import DataTable from 'react-data-table-component'
import customStyles from '../../assets/styles/Table Styles/tableStyle'
import classes from '../../assets/styles/employee.module.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get } from "../../server";
import { EMPLOYEE } from '../../utils/constants/apiUrl'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { BsThreeDotsVertical } from 'react-icons/bs'
import {
    Col, Row,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from 'reactstrap'
import { formatDistanceStrict } from 'date-fns';
import loadPermissions from '../../utils/functions/Permission'


const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const { employeeID } = useParams()
    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [employee, setEmployee] = useState()
    const [experience, setExperience] = useState([]);
    const [education, setEducation] = useState([])
    const [reporter, setReporter] = useState()
    const [totalExp, setTotalExp] = useState()
    const [permission, setPermission] = useState()
    useEffect(() => {
        if (loggedUser?.user?.userType === "employee")
            loadPermissions(loggedUser)
                .then((result) => {
                    setPermission(result.Permissions.find(data => data.name === "employees-edit"))
                })
    }, [loggedUser])

    async function loadEmployee() {
        let { status, data } = await get(
            EMPLOYEE + "/id/" + employeeID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const { annualSalary, status, hiringDate
            } = data
            const { username, firstName, lastName, phoneNo, email } = data.User
            setUser({
                username,
                firstName,
                lastName,
                phoneNo,
                email,
                status,
            })
            setEmployee({
                status,
                position: data.Position.name,
                department: data.Department.name,
                annualSalary,
                hiringDate
            })
            setExperience(data.Employee_Experience)
            setEducation(data.Employee_Education)
            setReporter(data.reporting_to)
            const targetDate = new Date(hiringDate)
            const currentDate = new Date()

            const distanceInMonths = formatDistanceStrict(targetDate, currentDate, { unit: 'month' })
            const distanceInYears = formatDistanceStrict(targetDate, currentDate, { unit: 'year' })
            let formattedDistance;
            if (parseInt(distanceInYears) === 1 && parseInt(distanceInMonths) >= 6) {
                formattedDistance = '1.5 years';
            } else if (parseInt(distanceInYears) === 1) {
                formattedDistance = '1 year';
            } else if (parseInt(distanceInYears) > 1) {
                formattedDistance = distanceInYears;
            } else {
                formattedDistance = distanceInMonths;
            }
            setTotalExp(formattedDistance)
        }
    }
    useEffect(() => {
        if (employeeID) loadEmployee()
    }, [])


    const experienceColumn = [
        {
            name: 'Company Name',
            selector: row => row.companyName,
            sortable: true
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.fromDate.substring(0, 10),
            sortable: true
        },
        {
            name: 'End Date',
            selector: row => row.toDate?.substring(0, 10),
            sortable: true
        }
    ]

    const educationColumn = [
        {
            name: 'Institute Name',
            selector: row => row.instituteName,
            sortable: true
        },
        {
            name: 'Degree Title',
            selector: row => row.degreeTitle,
            sortable: true,
        },
        {
            name: 'Degree Level',
            selector: row => row.degreeType,
            sortable: true
        },
        {
            name: 'Session',
            selector: row => row.session,
            sortable: true
        }
    ]

    return <>
        <Row>
            <Col xs="12">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Employee Detail</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/employee">Employee</Link></li>
                                        <li className="breadcrumb-item active">Employee Detail</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row >
            <Col sm="3" >
                <div className='card' >
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h2 className="card-title">Employee Info</h2>
                                </div>
                                <div className="col-sm-4">
                                    <div className="tools float-right">
                                        {(loggedUser?.user?.userType !== "employee" || permission) &&
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    caret
                                                    className="btn-icon"
                                                    color="link"
                                                    data-toggle="dropdown"
                                                    type="button"
                                                >
                                                    <BsThreeDotsVertical className={classes.dotsIcon} />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem
                                                        onClick={(e) => navigate(`/employee/AddEditEmployee/${employeeID}`)}
                                                    >
                                                        Edit Employee
                                                    </DropdownItem>
                                                    {/* <DropdownItem
                                                    className="text-danger"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    Deactivate
                                                </DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-body' style={{ overflow: 'hidden' }}>
                        <div className={classes.employeeCard}>

                            <div className={`card-header ${classes.employeeCardHeader}`}>
                                <label className={classes.employeeDepartment}>{employee?.department}</label>
                                <label className={classes.employeeJoining}>DOJ : {employee?.hiringDate?.slice(0, 10)}</label>

                            </div>

                            <div className={`card-body alignCenter`}>
                                <img src={defaultPicture} alt="" className={classes.EmployeePic} />

                                <div className={classes.EmployeeNameDiv}>
                                    <label className="EmployeeName">{user?.firstName} {user?.lastName}</label>
                                    <label className="EmployeeName">·</label>
                                    <label className={classes.EmployeeID}>ID-{employeeID}</label>
                                </div>

                                <div style={{ margin: "10px auto" }}>
                                    <label style={{ display: 'block' }}>{user?.email}</label>
                                    <label>{user?.phoneNo}</label>
                                </div>

                                <div style={{ textAlign: '-webkit-center', marginBottom: "10px" }}>
                                    <div className={classes.roleDiv}>
                                        <label className={classes.role}>Position: {employee?.position}</label>
                                        <label className={classes.role}>·</label>
                                        <label className={classes.role}>Exp: {totalExp}</label>
                                    </div>
                                </div>

                                <div className={classes.salary}>
                                    Salary: Rs {employee?.annualSalary}
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </Col>

            <Col xs="9">
                <Row>
                    <Col>
                        <div className='card' >
                            <div className="card-header">
                                <div className="container-fluid">
                                    <div className="row">
                                        <h3 className="card-title">Employee Experience</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={experienceColumn}
                                    data={experience}
                                    highlightOnHover
                                    responsive
                                    striped
                                    customStyles={customStyles}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='card' >
                            <div className="card-header">
                                <div className="container-fluid">
                                    <div className="row">
                                        <h3 className="card-title">Employee Education</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={educationColumn}
                                    data={education}
                                    highlightOnHover
                                    responsive
                                    striped
                                    customStyles={customStyles}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>

}

export default App;