import React, { useState, useEffect, useRef } from 'react'
import classes from '../../assets/styles/leave.module.css'
import { Preview } from '../../components/Modal/Preview'
import defaultPicture from '../../assets/images/defaultProfile.png'

import { TbSlideshow } from 'react-icons/tb'

import { Link, useNavigate, useParams } from "react-router-dom";
import { get } from "../../server";
import { LEAVE } from '../../utils/constants/apiUrl'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { formatDistanceStrict } from 'date-fns'

const App = () => {
    const navigate = useNavigate()
    const [loggedUser] = useAtom(loggedInUser)
    const { leaveID } = useParams()
    const [data, setData] = useState()
    const [employee, setEmployee] = useState()
    const [totalExp, setTotalExp] = useState()
    const [fullDayLeaves, setFullDayLeaves] = useState()
    const [halfDayLeaves, setHalfDayLeaves] = useState()
    const [files, setFiles] = useState([]);
    const previewData = useRef()
    const [showModal, setShowModal] = useState("close")
    const today = new Date()
    const disabledDates = { before: today };
    let fullDay = [];
    let halfDay = [];

    function handleSubmit(e) {
        e.preventDefault();
    }

    async function loadLeaves() {
        let { status, data } = await get(
            LEAVE + "/id/" + leaveID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const { User, Department, Position, hiringDate, id } = data.Employee
            const { username, firstName, lastName, phoneNo, email } = User
            setEmployee({
                id,
                username,
                firstName,
                lastName,
                phoneNo,
                email,
                position: Position.name,
                department: Department.name,
                hiringDate: hiringDate
            })
            const targetDate = new Date(hiringDate)
            const currentDate = new Date()

            const distanceInMonths = formatDistanceStrict(targetDate, currentDate, { unit: 'month' })
            const distanceInYears = formatDistanceStrict(targetDate, currentDate, { unit: 'year' })
            let formattedDistance;
            if (parseInt(distanceInYears) === 1 && parseInt(distanceInMonths) >= 6) {
                formattedDistance = '1.5 years';
            } else if (parseInt(distanceInYears) === 1) {
                formattedDistance = '1 year';
            } else if (parseInt(distanceInYears) > 1) {
                formattedDistance = distanceInYears;
            } else {
                formattedDistance = distanceInMonths;
            }
            setTotalExp(formattedDistance)
            setData(data)
            // setFullDayLeaves(data.Leave_Dates?.filter(leave => leave.leave === 'full'))
            // setHalfDayLeaves(data.Leave_Dates?.filter(leave => leave.leave === 'half'))
            for (let i = 0; i < data.Leave_Dates.length; i++) {
                const leave = data.Leave_Dates[i];
                if (leave.leave === 'full') {
                    fullDay.push(new Date(leave.date).toISOString().slice(0, 10));
                } else if (leave.leave === 'half') {
                    halfDay.push(new Date(leave.date).toISOString().slice(0, 10));
                }
            }
            setFullDayLeaves(fullDay)
            setHalfDayLeaves(halfDay)
        }
    }

    useEffect(() => {
        loadLeaves()
    }, [])

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };

    function LeaveType() {
        return (
            <div className={classes.DateGrid} >
                <div className={classes.panelHeader}>
                    <div>Leave Type</div>
                </div>
                <ul className={classes.panelList}>
                    {data?.Leave_Dates?.map((date, index) => (
                        <li className={classes.panelListli} key={index}>
                            <label className={classes.noMargin}>
                                <input
                                    type="radio"
                                    value="Full Day"
                                    checked={date?.leave === "full"}
                                    name={`leave_type_${index}`}
                                />
                                <span className={classes.leaveradio}>Full Day</span>
                            </label>
                            <label className={classes.noMargin}>
                                <input
                                    type="radio"
                                    value="Half Day"
                                    checked={date?.leave === "half"}
                                    name={`leave_type_${index}`}
                                />
                                <span className={classes.leaveradio}>Half Day</span>
                            </label>
                        </li>
                    ))
                    }
                </ul>
            </div>
        )
    }

    return <>

        <Row>
            <Col sm="12">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Leaves Details</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/leaves">Leaves</Link></li>
                                        <li className="breadcrumb-item active">Leaves Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="3" >
                <div className='card' >
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h2 className="card-title">Employee Info</h2>
                                </div>
                                <div className="col-sm-4">
                                    <div className="tools float-right">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                caret
                                                className="btn-icon"
                                                color="link"
                                                data-toggle="dropdown"
                                                type="button"
                                            >
                                                <BsThreeDotsVertical className={classes.dotsIcon} />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem
                                                    onClick={(e) => navigate(`/employee/info/${data?.employee_id}`)}
                                                >
                                                    View Employee
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-body' style={{ overflow: 'hidden' }}>
                        <div className={classes.employeeCard}>

                            <div className={`card-header ${classes.employeeCardHeader}`}>
                                <label className={classes.employeeDepartment}>{employee?.department}</label>
                            </div>

                            <div className={`card-body alignCenter`}>
                                <img src={defaultPicture} className={classes.EmployeePic} />

                                <div className={classes.EmployeeNameDiv}>
                                    <label className="EmployeeName">{employee?.firstName} {employee?.lastName}</label>
                                    <label className="EmployeeName">·</label>
                                    <label className={classes.EmployeeID}>ID-{employee?.id}</label>
                                </div>

                                <div style={{ margin: "10px auto" }}>
                                    <label style={{ display: 'block' }}>{employee?.email}</label>
                                    <label>{employee?.number}</label>
                                </div>

                                <div style={{ textAlign: '-webkit-center', marginBottom: "10px" }}>
                                    <div className={classes.roleDiv}>
                                        <label className={classes.role}>Position: {employee?.position}</label>
                                        <label className={classes.role}>·</label>
                                        <label className={classes.role}>Exp: {totalExp}</label>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </Col>

            <Col sm="4">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Details</h2>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className={classes.detailGrid}>
                            <label className={classes.detailStarter}>Reason</label>
                            <label className={classes.detailValue}>{data?.reason}</label>
                            <label className={classes.detailStarter}>Details</label>
                            <label className={classes.detailValue}>{data?.detail}</label>
                            <label className={classes.detailStarter}>From Date</label>
                            <label className={classes.detailValue}>{new Date(data?.fromDate).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</label>
                            <label className={classes.detailStarter}>To Date</label>
                            <label className={classes.detailValue}>{new Date(data?.toDate).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</label>
                            <label className={classes.detailStarter}>Total Days</label>
                            {/* <label className={classes.detailValue}>{Math.ceil(Math.abs((new Date(data?.to_date) - new Date(data?.fromDate)) / (1000 * 60 * 60 * 24) + 1))}</label> */}
                            <label className={classes.detailValue}>{data?.numDays}</label>
                            <label className={classes.detailStarter}>Last Updated</label>
                            <label className={classes.detailValue}>{new Date().toLocaleString('en-US', options)}</label>
                            <label className={classes.detailStarter}>First Approval</label>
                            <label className={classes.detailValue}>Pending</label>
                            <label className={classes.detailStarter}>Final Status</label>
                            <label className={classes.detailValue}>{data?.status}</label>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="5" >
                <div className='card' style={{ height: '457px' }}>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Leave Days</h2>
                            </div>
                        </div>
                    </div>

                    <div className='card-body' style={{ textAlign: '-webkit-center' }}>

                        <Calendar
                            value={data?.Leave_Dates?.map(leave => leave.date)}
                            multiple={true}
                            inputClass={classes.Dates}
                            // disabled={true}
                            plugins={[
                                <DatePanel />,
                                <LeaveType />
                            ]}
                        />
                    </div>
                </div>
            </Col>

            <Col sm="4">
                <div className='card' style={{ height: '309px' }}>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Files</h2>
                            </div>
                        </div>
                    </div>

                    {files.length > 0 && <div className='card-body'>
                        <div>
                            {files.map((file, index) => {
                                return (
                                    <div style={{ width: '100px' }}>
                                        {file.type.startsWith('image/') ? (
                                            <div>
                                                <img src={URL.createObjectURL(file)} alt="File preview" height="120px" width="100px" />
                                            </div>
                                        ) : (
                                            <iframe src={URL.createObjectURL(file)} title="File preview" height="100px" width="100px" />
                                        )}
                                        <div className={classes.previewIcons}>

                                            <TbSlideshow
                                                className={classes.previewIcon}
                                                onClick={() => {
                                                    setShowModal("preview")
                                                    previewData.current = file
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    }
                </div>
            </Col>

            <Col sm="8">
                <div className='card' style={{ height: "309px" }}>
                    <div className='card-body'>
                        <div style={{ position: 'relative' }}>
                            <div className={classes.leaveButtons}>
                                <button
                                    className={classes.accept}
                                    type="submit"
                                >
                                    Approve
                                </button>
                                <button
                                    className={`${classes.accept} ${classes.reject}`}
                                    type="submit"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Preview
                showModal={showModal === "preview"}
                setShowModal={setShowModal}
                file={previewData.current}
            />
        </Row>
    </ >
}

export default App;