import React, { useEffect, useState, useRef } from 'react'
import { NavLink, useLocation } from "react-router-dom";
import defaultPicture from '../assets/images/defaultProfile.png'
// import logoutUser from './../utils/functions/logoutUser';
import { useAtom } from 'jotai';
import { loggedInUser } from '../utils/store';
import Permission from '../utils/functions/Permission'
import { PropTypes } from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav, Collapse } from "reactstrap";
import routes from '../routes';
var ps;

// function SideNav({ name, role }) {
//     const [loggedUser] = useAtom(loggedInUser)
//     const location = useLocation();

//     const [permissions, setPermissions] = useState()
//     const [org_name, setOrgName] = useState()
//     const [position, setPosition] = useState()
//     useEffect(() => {
//         if (loggedUser?.role === "Employee")
//             Permission(loggedUser)
//                 .then(result => setPermissions(result))
//         if (loggedUser?.organization_name)
//             setOrgName(loggedUser?.organization_name)
//         if (loggedUser?.position)
//             setPosition(loggedUser?.position)
//     }, [loggedUser])

//     const department = permissions?.modules.find(module => module.name === "Departments")
//     const project = permissions?.modules.find(module => module.name === "Projects")
//     const employee = permissions?.modules.find(module => module.name === "Employees")
//     const attendance = permissions?.modules.find(module => module.name === "Attendance")
//     const leave = permissions?.modules.find(module => module.name === "Leaves")
//     // const late = permissions?.modules.find(module => module.name === "Late Criterias")
//     const jobs = permissions?.modules.find(module => module.name === "Job Openings")
//     const candidates = permissions?.modules.find(module => module.name === "Candidates")

//     return <>
//         <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{
//             // zIndex: 1
//         }}>
//             {/* Brand Logo */}
//             <Link to="/home" className="brand-link">
//                 {/* <img src={logo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
//                 <span className="brand-text font-weight-light">----HRM</span>
//             </Link>
//             {/* Sidebar */}
//             <div className="sidebar">
//                 {/* Sidebar Menu */}
//                 <nav className="mt-2">
//                     <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
//                         <div className="user-panel mt-3 pb-3 mb-3 d-flex">
//                             <div className="image">
//                                 <img src={defaultPicture} className="img-circle elevation-2" alt="User Image" />
//                             </div>
//                             <div className="info">
//                                 <a href="/home" className="d-block sideBarName">
//                                     {name}
//                                 </a>
//                                 {role === "Super Admin" ? <div className='sideBarCompany'>
//                                     {role}
//                                 </div>
//                                     : role === "Organization" ?
//                                         <div className='sideBarCompany'>
//                                             {org_name}
//                                         </div>
//                                         :
//                                         <div className='sideBarCompany'>
//                                             {position}
//                                         </div>
//                                 }
//                             </div>
//                         </div>

//                         <li className="nav-item" >
//                             <Link to="/home"
//                                 className={`nav-link ${location.pathname === "/home" ? "active" : null}`}
//                             >
//                                 <FaTachometerAlt className="nav-icon" />
//                                 <p>Dashboard</p>
//                             </Link>
//                         </li>

//                         {role === "Super Admin" && <li className="nav-item" >
//                             <Link to="/organization"
//                                 className={`nav-link ${location.pathname === "/organization" ? "active" : null}`}
//                             >
//                                 <GoOrganization className="nav-icon" />
//                                 <p>Organization</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization" || department?.actions.listing === 1) && <li className="nav-item" >
//                             <Link to="/department"
//                                 className={`nav-link ${location.pathname === "/department" ? "active" : null}`}
//                             >
//                                 <FaNetworkWired className="nav-icon" />
//                                 <p>Department</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization" || project?.actions.listing === 1) && <li className="nav-item" >
//                             <Link to="/project"
//                                 className={`nav-link ${location.pathname === "/project" ? "active" : null}`}
//                             >
//                                 <FaProjectDiagram className="nav-icon" />
//                                 <p>Project</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization" || employee?.actions.listing === 1 || attendance?.actions.listing === 1) && <li className="nav-item" >

//                             <a className="nav-link">
//                                 <FaUserAlt className="nav-icon" />

//                                 <p>{role !== "Employee" ? "Employee" : "Team"}
//                                     {(role === "Super Admin" || role === "Organization" || employee?.actions.listing === 1 || attendance?.actions.listing === 1) && <FaAngleLeft className="right" />}
//                                 </p>
//                             </a>

//                             <ul className="nav nav-treeview treeview">

//                                 {(role === "Super Admin" || role === "Organization" || employee?.actions.listing === 1) && <li className="nav-item" >
//                                     <Link to="/employee"
//                                         className={`nav-link ${location.pathname === "/employee" ? "active" : ""}`}
//                                     >
//                                         <FaUsers className="nav-icon" />
//                                         <p> {role !== "Employee" ? "Employees" : "Team Members"}</p>
//                                     </Link>
//                                 </li>
//                                 }

//                                 {(role === "Super Admin" || role === "Organization" || attendance?.actions.listing === 1) && <li className="nav-item" >
//                                     <Link to="/employee/attendance"
//                                         className={`nav-link ${location.pathname === "/employee/attendance" ? "active" : ""}`}
//                                     >
//                                         <FaUserCheck className="nav-icon" />
//                                         <p> Attendance</p>
//                                     </Link>
//                                 </li>
//                                 }

//                             </ul>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization" || leave?.actions.listing === 1 || leave?.actions.create === 1) && <li className="nav-item" >

//                             <a className="nav-link">
//                                 <SlCalender className="nav-icon" />

//                                 <p>Leaves
//                                     {(role === "Super Admin" || role === "Organization" || leave?.actions.listing === 1 || leave?.actions.create === 1) && <FaAngleLeft className="right" />}
//                                 </p>
//                             </a>

//                             <ul className="nav nav-treeview treeview">

//                                 {(role === "Super Admin" || role === "Organization" || leave?.actions.listing === 1) && <li className="nav-item" >
//                                     <Link to="/employee/leaves"
//                                         className={`nav-link ${location.pathname === "/employee/leaves" ? "active" : null}`}
//                                     >
//                                         <BiCircle className="nav-icon" />
//                                         <p>All Leaves</p>
//                                     </Link>
//                                 </li>
//                                 }

//                                 {role === "Employee" && <li className="nav-item" >
//                                     <Link to="/employee/my-leaves"
//                                         className={`nav-link ${location.pathname === "/employee/my-leaves" ? "active" : null}`}
//                                     >
//                                         <BiCircle className="nav-icon" />
//                                         <p>My Leaves</p>
//                                     </Link>
//                                 </li>
//                                 }

//                                 {(leave?.actions.create === 1) && <li className="nav-item" >
//                                     <Link to="/employee/leaves/apply"
//                                         className={`nav-link ${location.pathname === "/employee/leaves/apply" ? "active" : null}`}
//                                     >
//                                         <BiCircle className="nav-icon" />
//                                         <p>Apply for Leave</p>
//                                     </Link>
//                                 </li>
//                                 }
//                             </ul>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Employee") && <li className="nav-item" >
//                             <Link to="/checkin-and-checkout"
//                                 className={`nav-link ${location.pathname === "/checkin-and-checkout" ? "active" : null}`}
//                             >
//                                 <FaDoorOpen className="nav-icon" />
//                                 <p>Check In & Out</p>
//                             </Link>
//                         </li>
//                         }
//                         {(role === "Super Admin" || role === "Employee") && <li className="nav-item" >
//                             <Link to="/checkin-and-checkout/panel"
//                                 className={`nav-link ${location.pathname === "/checkin-and-checkout/panel" ? "active" : null}`}
//                             >
//                                 <FaDoorOpen className="nav-icon" />
//                                 <p>Check In & Out Panel</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization") && <li className="nav-item" >
//                             <Link to="/positions"
//                                 className={`nav-link ${location.pathname === "/positions" ? "active" : null}`}
//                             >
//                                 <BsBarChartSteps className="nav-icon" />
//                                 <p>Positions</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization") && <li className="nav-item" >
//                             <Link to="/roles-permissions"
//                                 className={`nav-link ${location.pathname === "/roles-permissions" ? "active" : null}`}
//                             >
//                                 <BiBarChartAlt2 className="nav-icon" />
//                                 <p>Roles & Permissions</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization" || jobs?.actions.listing === 1) && <li className="nav-item" >
//                             <Link to="/JobOpenings"
//                                 className={`nav-link ${location.pathname === "/JobOpenings" ? "active" : null}`}
//                             >
//                                 <MdWork className="nav-icon" />
//                                 <p>Job Openings</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization" || candidates?.actions.listing === 1) && <li className="nav-item" >
//                             <Link to="/candidate"
//                                 className={`nav-link ${location.pathname === "/candidate" ? "active" : null}`}
//                             >
//                                 <FaRegUser className="nav-icon" />
//                                 <p>Candidate</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization") && < li className="nav-item" >
//                             <Link to="/late-coming-criteria"
//                                 className={`nav-link ${location.pathname === "/late-coming-criteria" ? "active" : null}`}
//                             >
//                                 <BiLineChartDown className="nav-icon" />
//                                 <p>Late Comings Criteria</p>
//                             </Link>
//                         </li>
//                         }

//                         {(role === "Super Admin" || role === "Organization") && <li className="nav-item" style={{ cursor: 'pointer', color: "rgb(227, 82, 82)" }}>
//                             <Link to="/settings/leave-settings"
//                                 className={`nav-link ${location.pathname === "/settings/leave-settings" ? "active" : null}`}
//                             >
//                                 <RiChatSettingsLine className="nav-icon" />
//                                 <p >
//                                     Leave Settings Criteria
//                                 </p>
//                             </Link>
//                         </li>}

//                         <li className="nav-item" style={{ cursor: 'pointer', color: "rgb(227, 82, 82)" }}>
//                             <Link to="/settings"
//                                 className={`nav-link ${location.pathname === "/settings" ? "active" : null}`}
//                             >
//                                 <BiCog className="nav-icon" />
//                                 <p >
//                                     Settings
//                                 </p>
//                             </Link>
//                         </li>

//                         <li className="nav-item" style={{ cursor: 'pointer', color: "rgb(227, 82, 82)" }}>
//                             <a onClick={logoutUser} className="nav-link" >
//                                 <MdOutlineLogout className="nav-icon" style={{ color: "rgb(227, 82, 82)" }} />
//                                 <p style={{ color: "rgb(227, 82, 82)" }}>
//                                     Logout
//                                 </p>
//                             </a>
//                         </li>


//                     </ul>
//                 </nav>
//             </div>
//         </aside >

//     </ >
// }

// function SideNav({ name, role }) {
//     const location = useLocation();
//     const mainPanelRef = useRef(null);
//     const [sidebarOpened, setsidebarOpened] = useState(
//         document.documentElement.className.indexOf("nav-open") !== -1
//     );
//     document.documentElement.classList.toggle("nav-open")
//     useEffect(() => {
//         if (navigator.platform.indexOf("Win") > -1) {
//             document.documentElement.className += " perfect-scrollbar-on";
//             document.documentElement.classList.remove("perfect-scrollbar-off");
//             ps = new PerfectScrollbar(mainPanelRef.current, {
//                 suppressScrollX: true
//             });
//             let tables = document.querySelectorAll(".table-responsive");
//             for (let i = 0; i < tables.length; i++) {
//                 ps = new PerfectScrollbar(tables[i]);
//             }
//         }
//         // Specify how to clean up after this effect:
//         return function cleanup() {
//             if (navigator.platform.indexOf("Win") > -1) {
//                 ps.destroy();
//                 document.documentElement.classList.add("perfect-scrollbar-off");
//                 document.documentElement.classList.remove("perfect-scrollbar-on");
//             }
//         };
//     });
//     useEffect(() => {
//         if (navigator.platform.indexOf("Win") > -1) {
//             let tables = document.querySelectorAll(".table-responsive");
//             for (let i = 0; i < tables.length; i++) {
//                 ps = new PerfectScrollbar(tables[i]);
//             }
//         }
//         document.documentElement.scrollTop = 0;
//         document.scrollingElement.scrollTop = 0;
//         if (mainPanelRef.current) {
//             mainPanelRef.current.scrollTop = 0;
//         }
//     }, [location]);
//     // this function opens and closes the sidebar on small devices
//     const toggleSidebar = () => {
//         document.documentElement.classList.toggle("nav-open");
//         setsidebarOpened(!sidebarOpened);
//     };

//     return (
//         <BackgroundColorContext.Consumer>
//             {({ color, changeColor }) => (
//                 <>
//                     <div className="wrapper">
//                         <BackgroundColorContext.Consumer>
//                             {({ color }) => (
//                                 <div className="sidebar" data={color}>
//                                     <div className="sidebar-wrapper" >
//                                         <div className="logo">
//                                             <a
//                                                 href="/home"
//                                                 className="simple-text logo-mini"
//                                                 target="_blank"
//                                                 onClick={toggleSidebar}
//                                             >
//                                                 <div className="logo-img">
//                                                     <img src={defaultPicture} alt="react-logo" className='sideBarImage' />
//                                                 </div>
//                                             </a>

//                                             <a
//                                                 href="/home"
//                                                 className="simple-text logo-normal"
//                                                 target="_blank"
//                                                 onClick={toggleSidebar}
//                                             >
//                                                 <label className='sideBarName'>{name}</label>
//                                                 <div className='nameRole'>{role}</div>

//                                             </a>
//                                         </div>
//                                         <Nav>
//                                             {routes.map((prop, key) => {
//                                                 return (
//                                                     <li
//                                                         className={location.pathname === prop.path ? "active" : ""}
//                                                         key={key}
//                                                     >
//                                                         <NavLink
//                                                             to={prop.path}
//                                                             className="nav-link sideBarAlign"
//                                                             activeClassName="active"
//                                                             onClick={toggleSidebar}
//                                                         >
//                                                             <prop.icon className='sideBarIcons' />
//                                                             <p>{prop.name}</p>
//                                                         </NavLink>
//                                                     </li>
//                                                 );
//                                             })}
//                                             <li className="active-pro">
//                                                 <ReactstrapNavLink href="https://www.creative-tim.com/product/black-dashboard-pro-react?ref=bdr-user-archive-sidebar-upgrade-pro">
//                                                     <i className="tim-icons icon-spaceship" />
//                                                     <p>Upgrade to PRO</p>
//                                                 </ReactstrapNavLink>
//                                             </li>
//                                         </Nav>
//                                     </div>
//                                 </div>
//                             )}
//                         </BackgroundColorContext.Consumer>
//                         <div className="main-panel" ref={mainPanelRef} data={color}>
//                             <AdminNavbar
//                                 brandText={"HRMS"}
//                                 toggleSidebar={toggleSidebar}
//                                 sidebarOpened={sidebarOpened}
//                             />
//                             <div className="content">
//                                 <div className='card'>
//                                     {/* <section className='content-wrapper'> */}
//                                     <Outlet />
//                                     {/* </section> */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <FixedPlugin bgColor={color} handleBgClick={changeColor} />
//                 </>
//             )}
//         </BackgroundColorContext.Consumer>
//     );
// }

const SideNav = (props) => {
    const [state, setState] = useState({});
    const sidebarRef = useRef(null);
    const location = useLocation();
    const [loggedUser] = useAtom(loggedInUser)
    const [role, setRole] = useState()
    const [permissions, setPermissions] = useState()

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
        if (loggedUser?.user?.userType === "employee")
            Permission(loggedUser)
                .then(result => setPermissions(result.Permissions.map(data => ({ id: data.id, name: data.name }))));
    }, [loggedUser])

    useEffect(() => {
        setState(getCollapseStates(routes));
    }, []);
    useEffect(() => {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebarRef.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
        return function cleanup() {
            // we need to destroy the false scrollbar when we navigate
            // to a page that doesn't have this component rendered
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    // this creates the intial state of this component based on the collapse routes
    // that it gets through routes
    const getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    const getCollapseInitialState = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    };
    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            // let AllowedPath = permissions?.modules.find(module => module.name === prop.name)
            let AllowedPath = permissions?.find(role => role.name.split('-')[0] === prop.shortName)
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    (prop.permission?.role.includes(role) || AllowedPath) && <li
                        className={getCollapseInitialState(prop.views) ? "active" : ""}
                        key={key}
                    >
                        <a
                            href="#pablo"
                            data-toggle="collapse"
                            aria-expanded={state[prop.state]}
                            onClick={(e) => {
                                e.preventDefault();
                                setState({ ...state, ...st });
                            }}
                        >

                            <prop.icon className='sideBarIcons' />
                            <p>
                                {prop.name}
                                <b className="caret" />
                            </p>

                        </a>
                        <Collapse isOpen={state[prop.state]}>
                            <ul className="nav">{createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }

            return (
                <li className={activeRoute(prop.path)} key={key}>
                    {(prop.permission?.role.includes(role) ||
                        AllowedPath
                    )
                        && <NavLink
                            to={prop.path}
                            activeClassName=""
                            onClick={props.closeSidebar}
                        >

                            <prop.icon className='sideBarIcons' />
                            <p>{prop.name}</p>

                        </NavLink>}
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    const { activeColor } = props;
    let logoImg = null;
    let logoText = null;

    logoImg = (
        <NavLink
            to="/home"
            className="simple-text logo-mini"
            onClick={props.closeSidebar}
        >
            <div className="logo-img">
                <img src={defaultPicture} alt="react-logo" className='sideBarImage' />
            </div>
        </NavLink>
    );
    logoText = (
        <NavLink
            to="/home"
            className="simple-text logo-normal"
            onClick={props.closeSidebar}
        >
            <label className='sideBarName'>{props.name}</label>
            <div className='nameRole'>{props.role}</div>
        </NavLink>
    );
    return (
        <div className="sidebar" data={activeColor}>
            <div className="sidebar-wrapper" ref={sidebarRef}>
                {logoImg !== null || logoText !== null ? (
                    <div className="logo">
                        {logoImg}
                        {logoText}
                    </div>
                ) : null}
                <Nav>{createLinks(routes)}</Nav>
            </div>
        </div>
    );
};

SideNav.propTypes = {
    activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
    routes: PropTypes.array,
    logo: PropTypes.oneOfType([
        PropTypes.shape({
            innerLink: PropTypes.string.isRequired,
            imgSrc: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        }),
        PropTypes.shape({
            outterLink: PropTypes.string.isRequired,
            imgSrc: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ]),
    // this is used on responsive to close the sidebar on route navigation
    closeSidebar: PropTypes.func
};

export default SideNav
