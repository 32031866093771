import React from "react";
import { MutatingDots } from "react-loader-spinner";

const Loader = (props) => {

    return <>
        <MutatingDots
            color="#1d8cf8"
            secondaryColor="#1d8cf8"
            width="100"
            wrapperStyle={{ justifyContent: 'center' }}
        />
    </>
}
export default Loader