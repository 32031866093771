import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToast = (status, message) => {
    if (status === 200) {
        toast.success(message);
    } else if (typeof status === "undefined") {
        toast.error("No Server Response");
    } else if (status >= 400) {
        toast.error(message);
    } else {
        toast.error(message);
    }
};
export default showToast;
