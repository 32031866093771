import React, { useState } from 'react'
import classes from '../../assets/styles/settings.module.css'
import { USER } from '../../utils/constants/apiUrl'
import { post } from "../../server";
import { useNavigate } from "react-router-dom";
import showToast from '../../helpers/showToast'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { Row, Col, Card, CardBody, Button } from 'reactstrap';

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const navigate = useNavigate()
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [type, setType] = useState('password')

    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
        }
        else {
            setType('password')
        }
    }

    async function changePassword() {
        if (password !== confirmPassword)
            return showToast("error", "New Password & Confirm Password do not match")
        if (!password && !oldPassword & !confirmPassword)
            return 0
        let { status, message } = await post(
            USER + "/change/password",
            {
                current_password: oldPassword,
                new_password: password,
                confirm_password: confirmPassword
            },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            showToast(status, message)
            setTimeout(() => {
                navigate('/home')
            }, 2000);
        } else {
            showToast(status, message)
        }
    }

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className={classes.profileGroup}>
                            <label className="Starter">Old Password</label>
                            <input
                                value={oldPassword}
                                className="form-control passwordInputs"
                                onChange={(e) => setOldPassword(e.target.value)}
                                type={type}
                                placeholder="Old Password"
                            />
                            <label
                                className={classes.eye2}
                                onClick={handleToggle}
                            >
                                {type === "password" ? <FaEye /> : <FaEyeSlash />}
                            </label>
                        </div>

                        <div className={classes.profileGroup}>
                            <label className="Starter"> New Password</label>
                            <input
                                value={password}
                                className="form-control passwordInputs"
                                onChange={(e) => setPassword(e.target.value)}
                                type={type}
                                placeholder="New Password"
                            />
                            <label
                                className={classes.eye2}
                                onClick={handleToggle}
                            >
                                {type === "password" ? <FaEye /> : <FaEyeSlash />}
                            </label>
                        </div>

                        <div className={classes.profileGroup}>
                            <label className="Starter"> Confirm Password</label>
                            <input
                                value={confirmPassword}
                                className="form-control passwordInputs"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={type}
                                placeholder="Confirm Password"
                            />
                            <label
                                className={classes.eye2}
                                onClick={handleToggle}
                            >
                                {type === "password" ? <FaEye /> : <FaEyeSlash />}
                            </label>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className="rightItem">
                            <Button
                                color='info'
                                className="Createbutton"
                                onClick={(e) => {
                                    e.preventDefault()
                                    changePassword()
                                }}
                            >
                                Update Password
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default App;