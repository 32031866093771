import React, { useEffect, useState } from 'react'
import { Link, useNavigate, } from 'react-router-dom'
import Select from 'react-select'
import classes from '../../assets/styles/leave.module.css'
import { GrContactInfo } from 'react-icons/gr'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";

import { get } from "../../server";
import { LEAVE } from '../../utils/constants/apiUrl'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import TableComponent from '../../components/Global Components/DataTable';
import { AiOutlinePlus } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'

const MyLeaves = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const navigate = useNavigate()
    const options = [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' }
    ]

    const [data, setData] = useState()
    const [filteredData, setFilteredData] = useState()
    const [query, setQuery] = useState()

    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10);


    async function loadLeaves(page, size = perPage) {
        let { status, data } = await get(
            LEAVE + "/my-listing?page=" + page + "&perPage=" + size,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setTotalRows(data.totalItems)
            setData(data.data)
        }
    }

    useEffect(() => {
        loadLeaves(currentPage, perPage)
    }, [currentPage, perPage])

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
        },
        {
            name: 'From',
            selector: row => row.fromDate.substring(0, 10),
            sortable: true,
        },
        {
            name: 'To',
            selector: row => row.toDate.substring(0, 10),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            button: true,
            width: "120px",
            cell: row => <div style={{ textTransform: 'capitalize' }}>
                {row.status}
            </div>
        },
        {
            name: "Action",
            selector: row => row.action,
            button: true,
            cell: (row) => <div className={classes.actionIcons}>
                <Link to={`/leaves/detail/${row.id}`}
                >
                    <GrContactInfo
                        className={classes.infoIcon}
                    />
                </Link>
            </div>
        },
    ]
    const Data = data

    return <>
        <Card>
            <CardHeader>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">My Leaves</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Leaves</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardBody>
                <Button color='primary' className="create" onClick={() => navigate("/leaves/apply")}>
                    Create <AiOutlinePlus />
                </Button>
                <Button color="success" className='create' onClick={() => { setCurrentPage(1); setPerPage(10); loadLeaves(1, 10) }}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                <Select
                    options={options}
                    className={classes.Statusfilter}
                    isClearable={true}
                    value={query}
                    onChange={(e) => {
                        setFilteredData(Data?.filter(dat => dat.status.includes(e?.value)))
                        setQuery(e)
                    }}
                />
                {
                    Data && <TableComponent
                        columns={columns}
                        Data={Data}
                        filteredData={filteredData}
                        query={query}
                        allcheck={false}
                        paginationServer={true}
                        totalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                }

            </CardBody>
        </Card>
    </>
}

export default MyLeaves 