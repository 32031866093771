const Joi = require("joi");
const AddEditOrganizationSchema = Joi.object({
    id: Joi.allow(null),

    organization_name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Organization Name`,
        "string.min": `Organization Name must be minimum 1 characters!`,
        "string.max": `Organization Name must be maximum 25 characters!`,
    }),

    organization_Email: Joi.array().min(1).max(5).items(
        Joi.string().max(50).email({ tlds: { allow: false } })
    ).required().messages({
        "array.empty": `Please enter email address`,
        "array.min": `Please enter at least one email address`,
        "array.max": `You can enter up to 5 email addresses`,
        "string.email": `Please enter a valid email address`,
        "string.max": `Email must be maximum 50 characters`,
    }),

    organization_Phone_no: Joi.array().min(1).max(5).items(Joi.string().min(8).max(13)).required().messages({
        "array.empty": "Please enter your phone number",
        "array.min": "Please enter at least one phone number",
        "array.max": "You can enter up to 5 phone numbers",
    }),
    organization_address: Joi.string().allow("", null).label("Address"),
    organization_city: Joi.string().allow("", null).label("City"),
});

export default AddEditOrganizationSchema;
