import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import classes from '../../assets/styles/leave.module.css'
import { GrContactInfo } from 'react-icons/gr'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";

import { get, post } from "../../server";
import { EMPLOYEE, LEAVE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { loadOrganization } from '../../helpers/apis'
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import { Button } from 'reactstrap'
import { BiRefresh } from 'react-icons/bi'

const EmployeeLeaves = () => {
    const navigate = useNavigate()
    const [loggedUser] = useAtom(loggedInUser)
    const options = [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' }
    ]

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [filteredData, setFilteredData] = useState()
    const [query, setQuery] = useState("")
    const [select, setSelect] = useState(false)
    const leaveData = useRef()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10);


    async function loadLeaves(page, size = perPage) {
        console.log('here');
        let { status, data, message } = await get(
            LEAVE + "/listing?page=" + page + "&perPage=" + size,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setTotalRows(data.totalItems)
            setData(data.data)
            setDATA(data.data)
        }
        else if (status === 401)
            showToast(status, message)

    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadLeaves(currentPage, perPage)
    }, [currentPage, perPage])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        leaveData.current = selectedRows.map(row => row.id)
    }

    const Approve = (e) => {
        bulkStatus(leaveData.current, "approved")
    }

    const Reject = (e) => {
        bulkStatus(leaveData.current, "rejected")
    }

    async function changeStatus(id, e) {
        const value = { status: e.target.value }
        let { status, message } = await post(
            LEAVE + "/change/status/" + id,
            value,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status)
            showToast(status, message)
    }

    async function bulkStatus(items, formStatus) {
        let { status, message } = await post(
            LEAVE + "/bulk/change/status",
            { status: formStatus, ids: items },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadLeaves()
        }
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.Employee?.User?.firstName,
            sortable: true,
            cell: (row) => <div
                className={classes.pointer}
                onClick={() => navigate(`/employee/info/${row.id}`)}
            >
                {row.Employee?.User?.firstName} {row.Employee?.User?.lastName}
            </div >
        },
        {
            name: 'Email',
            selector: row => row.Employee?.User?.email,
            sortable: true
        },
        {
            name: 'Organization',
            selector: row => row.Employee?.Organization?.name,
            sortable: true
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
        },
        {
            name: 'From',
            selector: row => row.fromDate.substring(0, 10),
            sortable: true,
        },
        {
            name: 'To',
            selector: row => row.toDate.substring(0, 10),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            button: true,
            width: "150px",
            cell: (row) => <div>
                {row.id !== loggedUser?.id ? <select
                    name="status"
                    className="dropDown"
                    // value={row.status}
                    defaultValue={row.status}
                    onChange={(e) => {
                        changeStatus(row.id, e)
                    }}
                >
                    <option value="pending" className="dropdownOptions">Pending</option>
                    <option value="approved" className="dropdownOptions">Approved</option>
                    <option value="rejected" className="dropdownOptions" >Rejected</option>
                </select> : <div>{row.status} </div>}
            </div>
        },
        {
            name: "Action",
            selector: row => row.action,
            button: true,
            cell: (row) => <div className={classes.actionIcons}>
                <Link to={`/leaves/detail/${row.id}`}
                >
                    <GrContactInfo
                        className={classes.infoIcon}
                    />
                </Link>

            </div>

        },

    ]

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query)))
    }, [query])

    const handleStatusChange = selectedOptions => {
        if (selectedOptions === null) {
            if (organization)
                setDATA(data.filter(data => data.Employee?.organizationId === selectedOptions?.value))
            else
                setDATA(data)
        }
        else {
            const filtered = data.filter(data => data.status === selectedOptions?.label)
            if (query)
                setFilteredData(filtered.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query) ||
                    dat.department_name.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    }

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query)))
        }
        else {
            const filtered = data.filter(data => data.status === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query)))
            setDATA(filtered.filter(data => data.Employee?.organizationId === selectedOptions?.value))
        }
    };

    return <>
        <div className='card'>
            <div className="card-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">All Leaves</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">All Leaves</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="card-body">
                {select && <span>
                    <Button
                        color="danger"
                        className="activate reject"
                        onClick={Reject}
                    >
                        Reject
                    </Button>
                    <Button
                        color="info"
                        className="activate"
                        onClick={Approve}
                    >
                        Approve
                    </Button>
                </span>}
                <Button color="success" className='create' onClick={() => { setCurrentPage(1); setPerPage(10); loadLeaves(1, 10) }}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />
                {loggedUser?.user?.userType === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}
                <Select
                    options={options}
                    className={classes.Statusfilter}
                    isClearable={true}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                // onChange={(e) => e?.label ? setFilteredData(Data?.filter(dat => dat.status.includes(e?.label))) : setFilteredData(Data)}
                />
                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                    paginationServer={true}
                    totalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                />

            </section>
        </div>
    </>
}

export default EmployeeLeaves