import React, { useEffect, useState, useRef } from 'react'
import defaultPicture from '../../assets/images/defaultProfile.png'
import { ORGANIZATION } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { get, post } from "../../server";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import classes from '../../assets/styles/organization.module.css'

import CreationButton from '../../components/Global Components/CreationButton';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import ActiveButtons from '../../components/Global Components/ActiveButtons';
import EditAction from '../../components/Global Components/EditAction';
import { GrContactInfo } from 'react-icons/gr'
import { FaFilter } from 'react-icons/fa'
import { BiRefresh } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Organization = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const [Data, setData] = useState()
    const [query, setQuery] = useState("")
    const [select, setSelect] = useState(false)
    const userData = useRef()

    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10);

    const loadOrganization = async (page, size = perPage) => {
        let { status, message, data } = await get(
            ORGANIZATION + '/listing?page=' + page + "&perPage=" + size,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        setData(data.data)
        setTotalRows(data.totalItems)
        if (status !== 200) {
            showToast(status, message);
        }
    }

    useEffect(() => {
        loadOrganization(currentPage, perPage);
    }, [currentPage, perPage]);

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        userData.current = selectedRows.map(row => row.User.id)
    }

    const activate = (e) => {
        bulkStatus(userData.current, "active")
    }

    const Deactivate = (e) => {
        bulkStatus(userData.current, "inactive")
    }

    const changeStatus = async (id, e) => {
        const value = { status: e.target.value }
        let { status, message } = await post(
            ORGANIZATION + "/change/status/" + id,
            value,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status)
            showToast(status, message);
    }

    async function bulkStatus(items, userStatus) {
        let { status, message } = await post(
            ORGANIZATION + "/bulk/change/status",
            { status: userStatus, id: items },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadOrganization()
        }
    }

    const columns = [
        {
            selector: row => row.avatar,
            button: true,
            cell: (row) => <div>
                <img
                    src={row.avatar ? row.avatar :
                        defaultPicture}
                    alt="logo"
                    className={classes.tableImage}
                />
            </div>
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Number',
            selector: row => row.phoneNo,
            sortable: true
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: row => row.User?.userStatus,
            sortable: true,
            center: true,
            cell: row => <select
                name="status"
                className="dropDown"
                defaultValue={row.User?.userStatus}
                onChange={(e) => {
                    changeStatus(row.id, e)
                }}
            >
                <option value="active" className="dropdownOptions">Active</option>
                <option value="inactive" className="dropdownOptions">Inactive</option>
            </select>
        },
        {
            name: "Action",
            selector: row => row.action,
            button: true,
            cell: (row) => <div
                className={classes.actionIcons}
            >
                <Link to={`/organization/detail/${row.id}`} >
                    <GrContactInfo className="infoIcon" />
                </Link>

                <EditAction
                    link={"/organization/AddEditOrganization"}
                    id={row.id}
                />
            </div>
        },

    ]

    const [filteredData, setFilteredData] = useState()

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.name.toLowerCase().includes(query) ||
            dat.email.toLowerCase().includes(query) ||
            dat.phoneNo.toLowerCase().includes(query)
        ))
    }, [query])

    return <>
        <div className='card'>
            <div className="card-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Organization View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Organization</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-body">
                <Button color="success" className='create'><FaFilter className='buttonIcons' />Filter </Button>
                <CreationButton link={"/organization/AddEditOrganization"} />
                <Button color="success" className='create' onClick={() => { setPerPage(10); setCurrentPage(1) }}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                {
                    select && <ActiveButtons
                        activate={activate}
                        Deactivate={Deactivate}
                    />
                }

                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                <TableComponent
                    columns={columns}
                    Data={Data}
                    query={query}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    paginationServer={true}
                    totalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                />
            </section>
        </div>
    </>
}

export default Organization