import React, { useState, useEffect } from 'react'
import classes from '../../assets/styles/employee.module.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post, put } from "../../server";
import { EMPLOYEE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";

import UserProfile from './AddEmployee/UserDetails'
import EmployeeData from './AddEmployee/EmployeeDetails'
import ExperienceData from './AddEmployee/ExperienceData'
import EducationData from './AddEmployee/EducationData'
import DocumentData from './AddEmployee/DocumentData';
import { Col, Row } from 'reactstrap';

import { loadDepartment, loadOrganization, loadPositions, loadOrganizationEmployees, loadPermissions } from '../../helpers/apis'

const emptyUser = {
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    // avatar: "Default",
    job_title: "",
    annual_salary: "",
    hiring_date: new Date().toISOString().substr(0, 10),
    department_id: 0,
    position_id: 0,
    organization_role_id: "",
    experience: [],
    education: []
}

const emptyExperience = {
    company_name: "",
    designation: "",
    from_date: "",
    to_date: "",
};

const emptyEducation = {
    institute_name: "",
    degree_title: "",
    degree_type: "",
    session: "",
    session_from: "",
    session_to: "",
    documents: []
};


const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const navigate = useNavigate()
    const { employeeID } = useParams()

    const [employee, setEmployee] = useState(emptyUser)
    const [employeeExperience, setEmployeeExperience] = useState(emptyExperience)
    const [employeeEducation, setEmployeeEducation] = useState(emptyEducation)
    const [department, setDepartment] = useState()
    const [deleteExp, setDeleteExp] = useState([])
    const [deleteEdu, setDeleteEdu] = useState([])
    const [AddExperience, setAddExperience] = useState(false)
    const [AddEducation, setAddEducation] = useState(false)
    const [images, setImages] = useState([]);
    const [src, selectFile] = useState(null)
    const [role, setRole] = useState()
    const [position, setPosition] = useState()
    const [roleOptions, setRoleOptions] = useState()
    const [userRole, setUserRole] = useState()
    const [positionOptions, setPositionOptions] = useState()
    const [reportingHead, setReportingHead] = useState()

    useEffect(() => {
        if (loggedUser?.user?.userType) setRole(loggedUser?.user?.userType)
    }, [loggedUser])

    const [experience, setExperience] = useState([]);
    const [education, setEducation] = useState([])

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [departmentOptions, setdepartmentOptions] = useState([])
    const [AllDepartmentOptions, setAllDepartmentOptions] = useState([])
    const [organizationEmployees, setOrganizationEmployees] = useState()
    const [permissionOptions, setPermissionOptions] = useState()
    const [permission, setPermission] = useState()

    async function loadEmployee() {
        let { status, data } = await get(
            EMPLOYEE + "/id/" + employeeID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const {
                departmentId, annualSalary, positionId, jobTitle, hiringDate, organizationId, reportingHead } = data
            let newExp
            let newEdu
            if (data.Employee_Experience) {
                newExp = data.Employee_Experience.map((exp) => ({
                    id: exp.id,
                    company_name: exp.companyName,
                    designation: exp.designation,
                    from_date: exp.fromDate,
                    to_date: exp.toDate,
                }))
            }
            if (data.Employee_Education) {
                newEdu = data.Employee_Education.map((edu) => ({
                    id: edu.id,
                    degree_title: edu.degreeTitle,
                    degree_type: edu.degreeType,
                    institute_name: edu.instituteName,
                    session: edu.session,
                }))
            }
            setEmployee({
                username: data.User?.username,
                first_name: data.User?.firstName,
                last_name: data.User?.lastName,
                phone_no: data.User?.phoneNo,
                email: data.User?.email,
                // password: data.User?.password,
                // avatar: data.User?.avatar,
                job_title: jobTitle,
                hiring_date: hiringDate.slice(0, 10),
                annual_salary: annualSalary,
                organization_id: organizationId,
                position_id: positionId,
                department_id: departmentId,
                reporting_head: reportingHead,
                experience: data.Employee_Experience ? newExp : [],
                education: data.Employee_Education ? newEdu : [],
            })
            setOrganization({ value: organizationId, label: data.Organization.name })
            setDepartment({ value: departmentId, label: data.Department.name })
            setExperience(data.Employee_Experience ? newExp : [])
            setEducation(data.Employee_Education ? newEdu : [])
            if (data.Employee_Experience)
                setAddExperience(false)
            if (data.Employee_Education)
                setAddEducation(false)
            setPosition({
                value: positionId,
                label: data.Position.name,
                hierarchyLevel: data.Position?.hierarchyLevel,
                organization_id: data.Position?.organizationId
            })
        }
    }

    useEffect(() => {
        if (employeeID) loadEmployee()
    }, [])

    // useEffect(() => {
    //     const reporter = organizationEmployees?.find(items => items.value === employee.reporting_head)
    //     setReportingHead(reporter)
    // }, [employee, position, organizationEmployees])

    useEffect(() => {
        loadPositions(loggedUser, setPositionOptions)
        loadPermissions(loggedUser, setPermissionOptions)
        loadOrganization(loggedUser, setOrganizationOptions)
        loadDepartment(loggedUser, setAllDepartmentOptions)
        loadOrganizationEmployees(loggedUser, setOrganizationEmployees)
    }, [])

    useEffect(() => {
        if (role !== "super_admin")
            setdepartmentOptions(AllDepartmentOptions)
    }, [AllDepartmentOptions])


    useEffect(() => {
        if (employeeEducation.session_from && employeeEducation.session_to) {
            const session = employeeEducation.session_from + "-" + employeeEducation.session_to
            setEmployeeEducation({ ...employeeEducation, session: session })
        }
    }, [employeeEducation.session_from && employeeEducation.session_to])

    const handleSubmit = async () => {
        let { status, message } = await post(
            EMPLOYEE + "/addorUpdate" + (employeeID ? `/${employeeID}` : ""),
            employee,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                navigate('/employee')
        }
    };

    const [activeStep, setActiveStep] = useState(0);
    const [stepWizard, setStepWizard] = useState(null);
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    function handleStepClick(step) {
        setActiveStep(step);
        stepWizard.goToStep(step + 1);
    }

    return <>
        <Row>
            <Col sm="12">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{employeeID ? "Update " : "Create new "}Employee</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/employee">Employee</Link></li>
                                        <li className="breadcrumb-item active">Employee Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm="12">
                <div className='card'>
                    <div className="card-body">
                        <Stepper
                            activeStep={activeStep}
                            connectorStyleConfig={
                                {
                                    activeColor: '#343a40',
                                    completedColor: "#24415e",
                                    size: 2,

                                }
                            }
                            styleConfig={{
                                activeBgColor: '#343a40',
                                completedBgColor: "#24415e",
                                labelFontSize: "20px",
                                size: "2.5em"
                            }}
                        >
                            <Step label="User Details" children={" "} id="0" onClick={() => handleStepClick(0)} />
                            <Step label="Employee Details" children=" " id="1" onClick={() => handleStepClick(1)} disabled={employeeID && false} />
                            <Step label="Experience Data" children=" " id="2" onClick={() => handleStepClick(2)} disabled={employeeID && false} />
                            <Step label="Education Data" children=" " id="3" onClick={() => handleStepClick(3)} disabled={employeeID && false} />
                            <Step label="Document Data" children=" " id="4" onClick={() => handleStepClick(4)} disabled={employeeID && false} />
                        </Stepper>

                        <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>

                            <UserProfile
                                employee={employee}
                                setEmployee={setEmployee}
                            />

                            <EmployeeData
                                organization={organization}
                                setOrganization={setOrganization}
                                organizationOptions={organizationOptions}
                                department={department}
                                setDepartment={setDepartment}
                                AllDepartmentOptions={AllDepartmentOptions}
                                departmentOptions={departmentOptions}
                                setdepartmentOptions={setdepartmentOptions}
                                positionOptions={positionOptions}
                                employee={employee}
                                setEmployee={setEmployee}
                                role={role}
                                position={position}
                                setPosition={setPosition}
                                loggedUser={loggedUser}
                                roleOptions={roleOptions}
                                setRoleOptions={setRoleOptions}
                                userRole={userRole}
                                organizationEmployees={organizationEmployees}
                                reportingHead={reportingHead}
                                setReportingHead={setReportingHead}
                                permissionOptions={permissionOptions}
                                permission={permission}
                                setPermission={setPermission}
                            />

                            <ExperienceData
                                employee={employee}
                                setEmployee={setEmployee}
                                experience={experience}
                                setExperience={setExperience}
                                employeeExperience={employeeExperience}
                                setEmployeeExperience={setEmployeeExperience}
                                AddExperience={AddExperience}
                                setAddExperience={setAddExperience}
                                setDeleteExp={setDeleteExp}
                                loggedUser={loggedUser}
                            />
                            <EducationData
                                employee={employee}
                                setEmployee={setEmployee}
                                AddEducation={AddEducation}
                                setAddEducation={setAddEducation}
                                employeeEducation={employeeEducation}
                                setEmployeeEducation={setEmployeeEducation}
                                education={education}
                                setEducation={setEducation}
                                setDeleteEdu={setDeleteEdu}
                                loggedUser={loggedUser}
                            />
                            <DocumentData
                                completeCallback={handleSubmit}
                            />
                        </StepWizard>
                    </div>
                </div >
            </Col>
        </Row>
    </ >
}

export default App;