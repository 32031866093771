import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Delete } from '../../components/Modal/Delete'
import { BulkDelete } from '../../components/Modal/BulkDelete'
import classes from '../../assets/styles/jobOpening.module.css'
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import { RiFileInfoLine } from 'react-icons/ri'
import { BsPencilSquare } from 'react-icons/bs'
import { useAtom } from "jotai";
import { loggedInUser } from "../..//utils/store";
import { OPENING, USER } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { get, post } from "../../server";
import CreationButton from '../../components/Global Components/CreationButton';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import ActiveButtons from '../../components/Global Components/ActiveButtons';
import BulkDeleteButton from '../../components/Global Components/BulkDelete';
import DeleteAction from '../../components/Global Components/DeleteAction';
import { BiRefresh } from 'react-icons/bi';

const Openings = () => {
    const [loggedUser, setLoggedUser] = useAtom(loggedInUser)
    const location = useLocation()
    const org = new URLSearchParams(location.search).get('org');

    const [statusA, setStatusA] = useState(["Pending", "Interview", "Offered", "Accepted", "Rejected"])

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [showModal, setShowModal] = useState("close")
    const [select, setSelect] = useState(false)
    const [query, setQuery] = useState("")
    const [role, setRole] = useState()
    const OpenignData = useRef()
    const [filteredData, setFilteredData] = useState()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
    }, [loggedUser])

    useEffect(() => {
        if (org) {
            setOrganization(organizationOptions.find(data => data.value == org))
            setDATA(data?.filter(data => data.organizationId == org))
        }
    }, [org, organizationOptions, data])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        OpenignData.current = selectedRows
    }

    const activate = (e) => {
        bulkStatus(OpenignData.current.map(row => row.id), "open")
    }

    const Deactivate = (e) => {
        bulkStatus(OpenignData.current.map(row => row.id), "closed")
    }

    async function loadOpenings() {
        let { status, data } = await get(
            OPENING + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data)
            setDATA(data)
        }
    }

    async function changeStatus(id, e) {
        const value = { status: e.target.value }
        let { status, message } = await post(
            OPENING + "/change/status/" + id,
            value,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status)
            showToast(status, message)
    }

    async function bulkStatus(items, Status) {
        let { status, message } = await post(
            OPENING + "/bulk/change/status",
            { status: Status, ids: items },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadOpenings()
        }
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadOpenings()
    }, [])

    const columns = [
        {
            name: "Opening Name",
            sortable: true,
            selector: row => row.name
        },
        {
            name: "Organization",
            sortable: true,
            selector: row => row.Organization?.name,
            omit: role === "super_admin" ? false : true
        },
        {
            name: "Department",
            sortable: true,
            selector: row => row.Department?.name
        },
        {
            name: "Total Vacancies",
            sortable: true,
            center: true,
            selector: row => row.vacancies
        },
        {
            name: "Candidates Applied",
            sortable: true,
            center: true,
            selector: row => row.Candidates?.length
        },
        {
            name: "Status",
            sortable: true,
            center: true,
            selector: row => row.status,
            cell: row =>
                <select
                    name="status"
                    className="dropDown"
                    defaultValue={row.status}
                    onChange={(e) => {
                        changeStatus(row.id, e)
                    }}
                >
                    <option value="open" className="dropdownOptions">Open</option>
                    <option value="closed" className="dropdownOptions">Closed</option>
                </select>
        },
        {
            name: "Action",
            selector: row => row.action,
            cell: (row) => <div className={classes.actionIcons}>
                <DeleteAction
                    Data={OpenignData}
                    row={row}
                    setShowModal={setShowModal}
                    module="Openings"
                />
                <Link
                    to={`/openings/details/${row.id}`}
                    className={classes.infoIcon}
                >
                    <RiFileInfoLine />
                </Link>
                <Link
                    to={`/openings/AddEditJobOpenings/${row.id}`}
                    className={classes.edit}
                    module="Openings"
                >
                    <BsPencilSquare />
                </Link>

            </div>

        },

    ]

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.name.toLowerCase().includes(query)))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.name.toLowerCase().includes(query)))
        }
        else {
            const filtered = data.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.name.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    useEffect(() => { loadOpenings() }, [showModal])

    return <>

        <Card>
            <CardHeader>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Job Openings View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Job Openings View</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardBody>
                <CreationButton link={"/openings/AddEditJobOpenings"} module="Openings" />
                <Button color="success" className='create' onClick={() => loadOpenings()}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                {select && <ActiveButtons
                    activate={activate}
                    Deactivate={Deactivate}
                    module="Openings"
                />}

                {select && <BulkDeleteButton
                    setShowModal={setShowModal}
                    module="Openings"
                />}

                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                {role === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                />

                <Delete
                    showModal={showModal === "delete"}
                    setShowModal={setShowModal}
                    DATA={OpenignData.current}
                    type={"Department"}
                    module={OPENING}
                />

                <BulkDelete
                    showModal={showModal === "Bulk-delete"}
                    setShowModal={setShowModal}
                    DATA={OpenignData.current}
                    module={OPENING}
                />
            </CardBody>
        </Card>

    </>
}

export default Openings