import React, { useEffect, useRef, useState } from 'react'
import defaultPicture from '../assets/images/defaultProfile.png'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import classes from '../assets/styles/settings.module.css'
import { USER } from '../utils/constants/apiUrl'
import { get, post } from "../server";
import { Link, useNavigate } from "react-router-dom";
import showToast from '../helpers/showToast'
import Password from '../components/Settings/ChangePassword'
import Organization from '../components/Settings/EditOrganization'
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';

const Settings = () => {
    const [Data, setData] = useState()
    const [role, setRole] = useState()
    const [loggedUser] = useAtom(loggedInUser)
    const navigate = useNavigate()

    const [id, setId] = useState()
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState("")
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const [avatar, setAvatar] = useState([]);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState();

    const [name, setName] = useState([])

    async function loadUser() {
        let { status, data } = await get(
            USER + "/profile",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data)
            setRole(data.roleId)
        }
    }

    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (Data) {
            setId(Data.id)
            setFirstName(Data.firstName)
            setLastName(Data.lastName)
            setAvatar(Data.avatar)
            setEmail(Data.email)
            setNumber(Data.phoneNo)
            const name = first_name + " " + last_name
            setName(name)
        }
    }, [Data])

    async function editProfile() {
        let { status, message } = await post(
            USER + "/update/profile",
            {
                firstName: first_name,
                lastName: last_name,
                number,
                email,
                avatar: file
            },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            showToast(status, message)
            if (status === 200) {
                setTimeout(() => {
                    navigate('/home')
                }, 2000);
            }
        }
    }

    // async function uploadPic() {
    //     const data = new FormData()
    //     data.append("file", file)
    //     data.append("upload_preset", "hrms_system")
    //     data.append("cloud_name", "dahp7anty")
    //     fetch("https://api.cloudinary.com/v1_1/dahp7anty/image/upload", {
    //         method: "post",
    //         body: data
    //     })
    //         .then(resp => resp.json())
    //         .then(data => {
    //             editProfile(data.url)
    //         })
    //         .catch(err => console.log(err))
    // }

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Settings</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/home">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Settings</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3 customTabs"
                    transition={true}
                >
                    <Tab eventKey="profile" title="Profile">
                        <Row>
                            <Col sm="8">
                                <Card>
                                    <CardBody>
                                        <div className={classes.profileGroup}>
                                            <div className="Starter">First Name</div>
                                            <input
                                                value={first_name}
                                                className="form-control createInput"
                                                onChange={(e) => setFirstName(e.target.value)}
                                                type="text"
                                                placeholder="Elon"
                                            />
                                        </div>

                                        <div className={classes.profileGroup}>
                                            <div className="Starter">Last Name</div>
                                            <input
                                                value={last_name}
                                                className="form-control createInput"
                                                onChange={(e) => setLastName(e.target.value)}
                                                type="text"
                                                placeholder="Musk"
                                            />
                                        </div>

                                        <div className={classes.profileGroup}>
                                            <div className="Starter">Number</div>
                                            <input
                                                value={number}
                                                className="form-control createInput"
                                                onChange={(e) => setNumber(e.target.value)}
                                                type="tel"
                                                placeholder="Number"
                                            />
                                        </div>

                                        <div className={classes.profileGroup}>
                                            <div className="Starter">Email</div>
                                            <input
                                                value={email}
                                                className="form-control createInput"
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                placeholder="Email ID"
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="4">
                                <Card>
                                    <CardBody>
                                        <div className="centeredItem">
                                            <div>
                                                <img
                                                    src={avatar ? avatar : file ? URL.createObjectURL(file) : defaultPicture}
                                                    alt=""
                                                    className={classes.avatar}
                                                />
                                            </div>
                                            {/* <label className={classes.icons}>
                                                <label className={classes.ImageDiv2}>
                                                    <input
                                                        type='file'
                                                        className={classes.noDisplay}
                                                        onChange={(e) => {
                                                            selectFile(e.target.files[0])
                                                            setImages(URL.createObjectURL(e.target.files[0]))
                                                        }}
                                                        accept='image/*'
                                                    />
                                                    <BiImageAdd className={classes.imageIcon} />
                                                    {images &&
                                                        images !== "Default" ?
                                                        <FaTrashAlt
                                                            className={classes.deleteIcon} onClick={(e) => {
                                                                e.preventDefault()
                                                                setImages("Default")
                                                            }}
                                                        />
                                                        : null
                                                    }
                                                </label>
                                            </label> */}
                                            <input type="file"
                                                multiple
                                                onChange={handleFileChange}
                                                accept="image/*"
                                                className="noDisplay"
                                                ref={fileInputRef}
                                            />
                                            <br />
                                            {avatar ?
                                                <Button
                                                    color={"default"}
                                                    className={"default"}
                                                    onClick={() => handleClick()}
                                                >
                                                    Change Avatar
                                                </Button>
                                                :
                                                <Button
                                                    color={"default"}
                                                    className={"default"}
                                                    onClick={() => handleClick()}
                                                >
                                                    Select Avatar
                                                </Button>}
                                            {(avatar || file) && <Button
                                                color={"danger"}
                                                className={"default"}
                                                onClick={() => { setFile(); setAvatar() }}
                                            >
                                                Remove
                                            </Button>}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="rightItem">
                                            <Button className="Createbutton" color='info'
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    editProfile()
                                                }}
                                            >Update Profile</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="password" title="Password">
                        <Password />
                    </Tab>

                    {role === "Organization" && <Tab eventKey="organization" title="Organization Profile">
                        <Organization organizationID={Data.organization_id} />
                    </Tab>}

                </Tabs>
            </Col>

        </Row>


    </ >
}

export default Settings;