import React, { useEffect, useState, useRef } from 'react'
import defaultPicture from '../../assets/images/defaultProfile.png'
import classes from '../../assets/styles/Candidate.module.css'
import { useParams, Link } from "react-router-dom";
import { get } from "../../server";
import { CANDIDATE } from '../../utils/constants/apiUrl'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap";
import { AiOutlineDownload } from 'react-icons/ai';
import { TbSlideshow } from 'react-icons/tb';

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const { candidateID } = useParams()
    const previewData = useRef()
    const [files, setFiles] = useState([]);

    async function loadCandidate() {
        let { status, data } = await get(
            CANDIDATE + "/id/" + candidateID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data)
        }
    }
    useEffect(() => {
        if (candidateID) loadCandidate()
    }, [])

    const [data, setData] = useState()

    const [showModal, setShowModal] = useState(false);

    const handlePreviewClick = () => {
        setShowModal(true);
    };
    const options = { day: 'numeric', month: 'long', year: 'numeric' };

    return <>

        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Candidate Details</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/candidate">Candidates</Link></li>
                                        <li className="breadcrumb-item active">Candidate Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="4">
                <Card>
                    <CardHeader>
                        <Container fluid>
                            <Row>
                                <h2 className="card-title">
                                    Candidate Info
                                </h2>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.centeredItem}>
                            <img
                                src={defaultPicture}
                                alt=""
                                className={classes.displayImage}
                            />
                            <br />
                            <div className={classes.bigName}>
                                {data?.firstName} {data?.lastName}
                            </div>

                            <div style={{ margin: "10px auto" }}>
                                <label style={{ display: 'block' }}>{data?.email}</label>
                                <label>{data?.phoneNo}</label>
                            </div>

                            <div className={classes.candidateInfoDiv}>
                                <label className="EmployeeName">Experience</label>
                                <label className="EmployeeName">·</label>
                                <label className="EmployeeName">{data?.experienceYears}</label>
                            </div>
                            <div className={classes.candidateInfoDiv}>
                                <label className="EmployeeName">Expected Salary</label>
                                <label className="EmployeeName">·</label>
                                <label className="EmployeeName">Rs. {data?.expectedSalary}</label>
                            </div>
                            <div className={classes.candidateInfoDiv}>
                                <label className="EmployeeName">Dated</label>
                                <label className="EmployeeName">·</label>
                                <label className="EmployeeName">{data?.createdAt.slice(0, 10)}</label>
                            </div>

                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm="8">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Container fluid>
                                    <Row>
                                        <h2 className="card-title">
                                            History
                                        </h2>
                                    </Row>
                                </Container>
                            </CardHeader>
                            <CardBody>
                                <div className={classes.candidateHistory}>
                                    <label className={classes.candidateHistoryContent}>
                                        <label className={classes.boldContent}>Current Job Title</label>
                                        <label className={classes.values}>{data?.currentJobTitle}</label>
                                    </label>
                                    <label className={classes.candidateHistoryContent}>
                                        <label className={classes.boldContent}>Current Employer / Company</label>
                                        <label className={classes.values}>{data?.currentEmployer}</label>
                                    </label>
                                    <label className={classes.candidateHistoryContent}>
                                        <label className={classes.boldContent}>Skills</label>
                                        <label className={classes.values}>
                                            {data?.skillSet}
                                        </label>
                                    </label>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Container fluid>
                                    <Row>
                                        <h2 className="card-title">
                                            Hiring For
                                        </h2>
                                    </Row>
                                </Container>
                            </CardHeader>
                            <CardBody>
                                <div className={classes.candidateFor}>

                                    <label className={classes.boldContent}>Hire for Position</label>
                                    <label className={classes.values}>{data?.Job_Opening?.name}</label>

                                    <label className={classes.boldContent}>Position Level</label>
                                    <label className={classes.values}>{data?.Job_Opening?.Position?.name}</label>

                                    <label className={classes.boldContent}>Department</label>
                                    <label className={classes.values}>{data?.Job_Opening?.Department?.name}</label>

                                    <label className={classes.boldContent}>Status</label>
                                    <label className={classes.values}>{data?.status}</label>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col sm="6">
                <Card>
                    <CardHeader>
                        <Container fluid>
                            <Row>
                                <h2 className="card-title">
                                    Resume
                                </h2>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody>
                        {files?.map((file, index) => {
                            return (
                                <div style={{ width: '100px' }}>
                                    {file.type.startsWith('image/') ? (
                                        <div>
                                            <img src={URL.createObjectURL(file)} alt="File preview" height="120px" width="100px" />
                                        </div>
                                    ) : (
                                        <iframe src={URL.createObjectURL(file)} title="File preview" height="100px" width="100px" />
                                    )}
                                    <div className={classes.previewIcons}>

                                        <TbSlideshow
                                            className={classes.previewIcon}
                                            onClick={() => {
                                                setShowModal("preview")
                                                previewData.current = file
                                            }}
                                        />
                                        <AiOutlineDownload
                                            // onClick={(ee) => {
                                            //     let newFiles = [...files]
                                            //     newFiles.splice(index, 1)
                                            //     setFiles(newFiles)
                                            // }}
                                            className={classes.deleteIcon}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div>
                            <label
                                className={`${classes.values} ${classes.boldValue}`}
                            >
                                Resume Name
                            </label>
                            <button
                                onClick={handlePreviewClick}
                                className={classes.AddButton}
                            >
                                Preview
                            </button>
                            <button
                                className={classes.AddButton}
                                onClick={handlePreviewClick}
                            >
                                Download
                            </button>
                        </div> */}
                    </CardBody>
                </Card>
            </Col>

        </Row>

    </>

}

export default App;