import classes from '../../assets/styles/leave.module.css'

export const Preview = ({ showModal, setShowModal, file }) => {
    return <>{file !== undefined ?
        showModal ? <div className='backgroundBlur' >
            <div className='content-wrap' >
                <div className={file.type.startsWith('image/') ? (classes.leaveModal) : (classes.leaveModalPDF)}>
                    {file.type.startsWith('image/') ? (
                        <img src={URL.createObjectURL(file)} alt="File preview" height="90%" />
                    ) : (
                        <iframe src={URL.createObjectURL(file)} title="File preview" width="100%" height="90%" />
                    )}
                    <br />
                    <br />
                    <button className={classes.SaveButton}
                        onClick={(e) => setShowModal("close")}
                    >
                        Close
                    </button>
                    <br />
                </div>
            </div>
        </div> : null : null
    }</>

}