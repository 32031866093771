import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import classes from '../../../assets/styles/employee.module.css'
import ValidateSchema from "../../../helpers/ValidateSchema";
import AddEditEmployeeSchema from '../../../utils/SchemasValidation/AddEditEmployeeSchema'
import ActionButtons from './ActionButtons'

const EmployeeData = (props) => {
    const [EmployeeErrors, setEmployeeErrors] = useState({});
    const [positionOptions, setPositionOptions] = useState()
    const [permissionOptions, setPermissionOptions] = useState()
    const [headOptions, setHeadOptions] = useState()

    useEffect(() => {
        props.setdepartmentOptions(props.AllDepartmentOptions?.filter(data => data.organization_id === props.organization?.value))
        setPositionOptions(props.positionOptions?.filter(items => items.organization_id === props.organization?.value))
        setPermissionOptions(props.permissionOptions?.filter(items => items.organization_id === props.organization?.value))
    }, [props.organization])

    useEffect(() => {
        if (props.role !== "super_admin") {
            setPositionOptions(props.positionOptions)
            setPermissionOptions(props.permissionOptions)
        }
    }, [props.role, props.positionOptions, props.permissionOptions])

    useEffect(() => {
        const level = props.positionOptions?.reduce(
            (acc, { hierarchyLevel }) => ({
                min: hierarchyLevel < acc.min ? hierarchyLevel : acc.min,
                max: hierarchyLevel > acc.max ? hierarchyLevel : acc.max,
            }),
            { min: Infinity, max: -Infinity }
        );
        const highLevel = level?.max
        const lowLevel = level?.min
        const hierarchyLevel = props.position?.hierarchyLevel
        const isAbove = hierarchyLevel <= highLevel
        const isBelow = hierarchyLevel >= lowLevel

        let filteredData = []
        const filter = props.organizationEmployees?.filter(obj => obj.organization_id === props.department?.organization_id)
        if (isAbove) {
            filteredData = filter?.filter(obj => obj.hierarchyLevel < hierarchyLevel)
        } else if (isBelow) {
            filteredData = filter?.filter(obj => obj.hierarchyLevel > hierarchyLevel)
        }
        setHeadOptions(filteredData)
    }, [props.position, props.department])

    const validate = () => {
        let EErrors = ValidateSchema(props.employee, AddEditEmployeeSchema)
        setEmployeeErrors(EErrors)
        if (Object.keys(EErrors).length) return false;
        else {
            props.nextStep();
        }
        return true;
    };

    const handleEmployeeChange = (e) => {
        const name = e.target.name
        const value = e.target.value;
        props.setEmployee({ ...props.employee, [name]: value });
    };

    const handleOrganizationSelect = (e) => {
        props.setOrganization(e)
        props.setEmployee({
            ...props.employee,
            organization_id: e.value,
            department_id: "",
            organization_role_id: "",
            position_id: "",
            reporting_head: 0,
        })
        props.setdepartmentOptions(props.AllDepartmentOptions?.filter(data => data.organization_id === props.organization?.value))
        setPositionOptions(props.positionOptions?.filter(items => items.organization_id === props.organization?.value))
        setPermissionOptions(props.permissionOptions?.filter(items => items.organization_id === props.organization?.value))
        props.setDepartment(null)
        props.setPermission(null)
        props.setPosition(null)
        props.setReportingHead(null)
    }

    const handleDepartmentSelect = (e) => {
        props.setDepartment(e)
        props.setEmployee({ ...props.employee, department_id: e.value })
    }

    const handlePermissionSelect = (e) => {
        props.setPermission(e)
        props.setEmployee({ ...props.employee, organization_role_id: e.value })
    }

    const handlePositionSelect = (e) => {
        props.setPosition(e)
        props.setEmployee({ ...props.employee, position_id: e.value })
    }

    const handleHeadSelect = (e) => {
        props.setReportingHead(e)
        props.setEmployee({ ...props.employee, reporting_head: e.value })
    }

    useEffect(() => {
        // let filteredOptions = [...employeeOptions];
        // const index = employeeOptions.findIndex((option) => option.label === props.position);
        // if (index !== -1) {
        //     filteredOptions.splice(0, index + 1);
        // }
        // setEmployeeOptions(filteredOptions)
    }, [props.position])

    return <>
        <div className={classes.EmployeeView}>

            <div>
                <label className='Starter'>Job Title*</label>
                <input
                    value={props.employee?.job_title}
                    className={EmployeeErrors?.job_title ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleEmployeeChange}
                    type="text"
                    placeholder="Job Title"
                    name="job_title"
                />
                <p className="errorText">{EmployeeErrors?.job_title || ""}</p>
            </div>

            <div>
                <label className='Starter'>Hiring Date*</label>
                <input
                    value={props.employee?.hiring_date}
                    className={EmployeeErrors?.hiring_date ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleEmployeeChange}
                    type="date"
                    placeholder="Hiring Date"
                    name="hiring_date"
                />
                <p className="errorText">{EmployeeErrors?.hiring_date || ""}</p>
            </div>


            {props.role === "super_admin" && <div>
                <label className='Starter'>Organization*</label>
                <Select
                    options={props.organizationOptions}
                    placeholder="Select Organization"
                    value={props.organization}
                    onChange={handleOrganizationSelect}
                    className={EmployeeErrors?.department_name ? "EdropDown errorInput " : "EdropDown"}
                />
                <p className="errorText">{EmployeeErrors?.department_name || ""}</p>
            </div>}


            <div>
                <label className='Starter'>Department*</label>
                <Select
                    value={props.department}
                    options={props.departmentOptions}
                    placeholder="Select Department"
                    onChange={handleDepartmentSelect}
                    className={EmployeeErrors?.department_id ? "EdropDown errorInput " : "EdropDown"}
                />
                <p className="errorText">{EmployeeErrors?.department_id || ""}</p>
            </div>

            <div>
                <label className='Starter'>Roles & Permissions*</label>
                <Select
                    options={permissionOptions}
                    placeholder="Select Roles"
                    value={props.permission}
                    onChange={handlePermissionSelect}
                    className={EmployeeErrors?.userRole ? "EdropDown errorInput " : "EdropDown"}
                />
                <p className="errorText">{EmployeeErrors?.userRole || ""}</p>
            </div>

            <div>
                <label className='Starter'>Position</label>
                <Select
                    options={positionOptions}
                    placeholder="Select Position"
                    value={props.position}
                    onChange={handlePositionSelect}
                    className={EmployeeErrors?.position_id ? "EdropDown errorInput " : "EdropDown"}
                />
                <p className="errorText">{EmployeeErrors?.position_id || ""}</p>
            </div>

            <div>
                <label className='Starter'>Reporting Head</label>
                <Select
                    options={headOptions}
                    placeholder="Select Reporting Head"
                    value={props.reportingHead}
                    isDisabled={!headOptions?.length}
                    isClearable={true}
                    onChange={handleHeadSelect}
                    className={EmployeeErrors?.reporting_head ? "EdropDown errorInput " : "EdropDown"}
                />
                <p className="errorText">{EmployeeErrors?.reporting_head || ""}</p>
            </div>

            <div>
                <label className='Starter'>Annual Salary*</label>
                <input
                    value={props.employee?.annual_salary}
                    className={EmployeeErrors?.annual_salary ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleEmployeeChange}
                    type="number"
                    placeholder="Salary"
                    name="annual_salary"
                />
                <p className="errorText">{EmployeeErrors?.annual_salary || ""}</p>
            </div>


        </div>
        {/* <hr className={classes.hrClass} /> */}
        <br />
        <ActionButtons {...props} nextStep={validate} />
    </>
}

export default EmployeeData