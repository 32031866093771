import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import classes from '../../assets/styles/position.module.css'
import { useParams, useNavigate } from "react-router-dom";
import { get, post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import PositionsSchema from '../../utils/SchemasValidation/PositionsSchema'
import { POSITION } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";

import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { loadOrganization } from '../../helpers/apis'

const emptyPosition = {
    name: "",
    organization_id: "",
    parent_id: "",
    hierarchy_level: ""
};

const App = () => {
    const { positionID } = useParams()
    const [loggedUser] = useAtom(loggedInUser)

    const [position, setPosition] = useState(emptyPosition)
    const navigate = useNavigate()
    const [role, setRole] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [organization, setOrganization] = useState([])

    const [parents, setParents] = useState()
    const [parentOptions, setParentOptions] = useState()
    const [parent, setParent] = useState()

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
    }, [loggedUser])

    async function loadPositions() {
        let { status, data } = await get(
            POSITION + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setParents(data.map(items => ({ value: items.id, label: items.name, organizationId: items.organizationId })))
        }
    }

    async function loadPosition() {
        let { status, data } = await get(
            POSITION + `/id/${positionID}`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setPosition({
                name: data.name,
                organization_id: data.organizationId,
                parent_id: data.parentId,
                hierarchy_level: data.hierarchyLevel
            })
            setOrganization({ value: data.Organization?.id, label: data.Organization?.name })
            setParent({ value: data.parent?.id, label: data.parent?.name })
            // const parentFilter = parents?.filter(dat => dat.value != data.id)
            // setParentOptions(parentFilter)
        }
    }

    useEffect(() => {
        const parentFilter = parents?.filter(dat => dat.value != positionID)
        setParentOptions(parentFilter)
    }, [positionID, parent, parents])

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let Errors = ValidateSchema(position, PositionsSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadPositions()
    }, [])

    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === "hierarchy_level")
            value = (+value)
        setPosition({ ...position, [name]: value });
    };

    const handleSelectChange = (selectedOption) => {
        setParent(selectedOption)
        setPosition({ ...position, parent_id: selectedOption?.value })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        if (position.parent_id === "")
            delete position.parent_id
        let { status, message } = await post(
            POSITION + "/addorUpdate" + (positionID ? `/${positionID}` : ""),
            position,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            navigate("/positions");
        }
        showToast(status, message);
    };

    useEffect(() => {
        if (positionID) loadPosition();
    }, [])

    return <>

        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{positionID ? "Update" : "Create"} Positions</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/positions">Position</Link></li>
                                        <li className="breadcrumb-item active">Positions Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Positions Data</h2>
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <div className={classes.positionView}>
                            {role === "super_admin" && <div>
                                <label className='Starter'>
                                    Organization
                                </label>
                                <Select
                                    options={organizationOptions}
                                    value={organization}
                                    onChange={(e) => {
                                        setOrganization(e)
                                        setPosition({ ...position, organization_id: e.value })
                                        setParentOptions(parents.filter(rows => rows.organizationId === e.value))
                                    }}
                                    name="organization_id"
                                    className={errors?.organization_name ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.organization_id || ""}</p>
                            </div>}

                            <div>
                                <label className='Starter'>Position Name</label>
                                <input
                                    value={position.name}
                                    className={errors?.name ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Position Name"
                                    name="name"
                                />
                                <p className="errorText">{errors?.name || ""}</p>
                            </div>

                            <div>
                                <label className='Starter'>
                                    Parent
                                </label>
                                <Select
                                    options={parentOptions}
                                    value={parent}
                                    isClearable={true}
                                    onChange={handleSelectChange}
                                    name="lead"
                                    className={errors?.parent_id ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.parent_id || ""}</p>
                            </div>

                            <div>
                                <label className='Starter'>Level</label>
                                <input
                                    value={position.hierarchy_level}
                                    className={errors?.hierarchy_level ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleChange}
                                    type="number"
                                    placeholder="Level"
                                    name="hierarchy_level"
                                />
                                <p className="errorText">{errors?.hierarchy_level || ""}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className="rightItem">
                            <Button
                                color="info"
                                className="Createbutton"
                                onClick={handleSubmit}
                            >
                                {positionID ? "Update " : "Create "}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Row>


    </>
}

export default App;