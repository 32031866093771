const Joi = require("joi");
const AddEditUserSchema = Joi.object({
    id: Joi.allow(null),

    first_name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter First name`,
        "any.required": `Please enter First name`,
        "string.min": `First name must be minimum 1 characters!`,
        "string.max": `First name must be maximum 25 characters!`,
    }),

    last_name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Last name`,
        "any.required": `Please enter Last name`,
        "string.min": `Last name must be minimum 1 characters!`,
        "string.max": `Last name must be maximum 25 characters!`,
    }),
    username: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Username`,
        "any.required": `Please enter Username`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),
    // password: Joi.string().min(5).required().messages({
    //     "string.empty": `Please enter Password`,
    //     "any.required": `Please enter Password`,
    //     "string.min": `Password must be minimum 5 characters!`,
    // }),
    email: Joi.string()
        .max(50)
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": `Please enter Email Address`,
            "any.required": `Please enter Email Address`,
            "string.email": `Please enter valid email address`,
            "string.max": `Email must be maximum 50 characters!`,
        }),
    phone_no: Joi.number().min(8).required().messages({
        "number.base": `Please enter your Phone Number`,
        "any.required": `Please enter your Phone Number`,
        "string.min": `Phone number must be 8 characters!`,
    })
});

export default AddEditUserSchema;
