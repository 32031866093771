import { get } from "../../server";
import { ROLE } from "../constants/apiUrl";

async function loadPermissions(loggedUser) {
    let { status, data } = await get(
        ROLE + "/permissions",
        Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
    );
    if (status === 200) {
        return data
    }
}

export default loadPermissions