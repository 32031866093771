const Joi = require("joi");
const AddEditProjectSchema = Joi.object({
    id: Joi.allow(null),

    name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Project Name`,
        "string.min": `Project Name must be minimum 1 characters!`,
        "string.max": `Project Name must be maximum 25 characters!`,
    }),
    // departments: Joi.array().min(1).max(5).required().messages({
    //     "array.empty": `Please Select Department`,
    //     "array.min": `Please select at least one department`,
    //     "array.max": `You can select up to 5 department`,
    // }),
    // leads: Joi.array().min(1).max(5).required().messages({
    //     "array.empty": `Please Select Lead`,
    //     "array.min": `Please select at least one Lead`,
    //     "array.max": `You can select up to 5 Leads`,
    // })

});

export default AddEditProjectSchema;
