import React, { useEffect, useState, useRef } from 'react'
import defaultPicture from '../../assets/images/defaultProfile.png'
import { CANDIDATE, DEPARTMENT, EMPLOYEE, OPENING, ORGANIZATION, PROJECT } from '../../utils/constants/apiUrl'
import { get } from "../../server";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import classes from '../../assets/styles/organization.module.css'
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs'
import { BiUser } from 'react-icons/bi'
import { PieChart } from 'react-minimal-pie-chart'
import { FaNetworkWired, FaArrowCircleRight, FaProjectDiagram, FaUsers, FaRegUser } from 'react-icons/fa'
import { MdWork } from 'react-icons/md'
import { Col, Row } from 'reactstrap';

import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,

} from "reactstrap";
import { Delete } from '../../components/Modal/Delete';

const defaultLabelStyle = {
    fontSize: '10px',
    fontFamily: 'sans-serif',
    color: 'white'
};

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const { organizationID } = useParams()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState("close")
    const [organization, setOrganization] = useState()
    const [departments, setDepartments] = useState()
    const departmentData = useRef()
    const [projects, setProject] = useState(0)
    const [employee, setEmployee] = useState(0)
    const [openings, setOpenings] = useState(0)
    const [candidate, setCandidates] = useState(0)

    const loadOrganization = async () => {
        let { status, data } = await get(
            ORGANIZATION + `/${organizationID}`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            // data.email = data.email.split(",")
            // data.phoneNo = data.phoneNo.split(",")
            setOrganization(data)
        }
    };

    const loadDepartments = async () => {
        let { status, data } = await get(
            DEPARTMENT + "/listing/" + organizationID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200)
            setDepartments(data)
    };

    async function loadProjects() {
        let { status, data } = await get(
            PROJECT + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setProject(data.length)
        }
    }


    async function loadEmployees() {
        let { status, data } = await get(
            EMPLOYEE + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setEmployee(data.length)
        }
    }

    async function loadOpenings() {
        let { status, data } = await get(
            OPENING + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setOpenings(data.length)
        }
    }

    async function loadCandidates() {
        let { status, data } = await get(
            CANDIDATE + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setCandidates(data.length)
        }
    }

    useEffect(() => {
        if (organizationID) {
            loadOrganization()
            loadDepartments()
            loadProjects()
            loadEmployees()
            loadOpenings()
            loadCandidates()
        }
    }, [])

    useEffect(() => { loadDepartments() }, [showModal])

    return <>

        <Row >
            <Col xs="12">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{organization?.name}</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/organization">Organization</Link></li>
                                        <li className="breadcrumb-item active">Organization Detail</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="3" >
                <div className='card' >
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h3 className="card-title">Organization Details</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className={classes.centeredItem}>
                            <img
                                src={organization?.avatar ? organization?.avatar : defaultPicture}
                                alt=""
                                className={classes.displayImage}
                                width="160px"
                            />
                            <br />
                            <div
                                className={classes.orgName}>
                                {organization?.name}
                            </div>
                        </div>
                        <br />
                        <div className={classes.OrgContent}>

                            <label className={classes.Starter}>Email IDs</label>
                            <label className={classes.values}>{organization?.email}</label>

                            <label className={classes.Starter}>Contact No.</label>
                            <label className={classes.values}>{organization?.phoneNo}</label>

                            <label className={classes.Starter}>City of Operation</label>
                            <label className={classes.values}>{organization?.city}</label>

                            <label className={classes.Starter}>Address</label>
                            <label className={classes.values}>{organization?.address}</label>

                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="3">
                <div className='card organizationCardHeight'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h3 className="card-title">Contact Information</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className={classes.centeredItem}>
                            <img
                                src={organization?.User.avatar ? organization?.User.avatar : defaultPicture}
                                alt=""
                                className={classes.displayImage}
                            // width="160px"
                            // height="160px"
                            />
                            <br />
                            <div
                                className={classes.orgName}>
                                {organization?.User.firstName} {organization?.User.lastName}
                            </div>
                        </div>
                        <br />
                        <div className={classes.OrgContent}>

                            <label className={classes.Starter}>Username</label>
                            <label className={classes.values}>{organization?.User.username}</label>

                            <label className={classes.Starter}>Email ID</label>
                            <label className={classes.values}>{organization?.User.email}</label>

                            <label className={classes.Starter}>Contact No.</label>
                            <label className={classes.values}>{organization?.User.phoneNo}</label>

                        </div>
                    </div>
                </div>
            </Col>

            <Col xs="6">
                <div className='card organizationCardHeight'>
                    <div className="card-body">

                        <div className={classes.cardFlex}>
                            <div className={classes.box}>
                                <div className={`${classes.smallBox} ${classes.box1}`}>
                                    <div className={classes.inner}>
                                        <h3>{departments?.length ? departments?.length : "0"}</h3>
                                        <p>Departments</p>
                                    </div>
                                    <div className={classes.icon}>
                                        <FaNetworkWired />
                                    </div>
                                    <Link className={classes.smallBoxFooter} to={`/department?org=${organizationID}`}>
                                        More info
                                        <FaArrowCircleRight className={classes.arrow} />
                                    </Link>
                                </div>
                            </div>

                            <div className={classes.box}>
                                <div className={`${classes.smallBox} ${classes.box2}`}>
                                    <div className={classes.inner}>
                                        <h3>{projects}</h3>
                                        <p>Projects</p>
                                    </div>
                                    <div className={classes.icon}>
                                        <FaProjectDiagram />
                                    </div>
                                    <Link className={classes.smallBoxFooter} to={`/project?org=${organizationID}`}>
                                        More info
                                        <FaArrowCircleRight className={classes.arrow} />
                                    </Link>
                                </div>
                            </div>

                            <div className={classes.box}>
                                <div className={`${classes.smallBox} ${classes.box3}`}>
                                    <div className={classes.inner}>
                                        <h3>{employee}</h3>
                                        <p>Employees</p>
                                    </div>
                                    <div className={classes.icon}>
                                        <FaUsers />
                                    </div>
                                    <Link className={classes.smallBoxFooter} to={`/employee?org=${organizationID}`}>
                                        More info
                                        <FaArrowCircleRight className={classes.arrow} />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <div className={classes.cardFlex}>
                            <div className={classes.box}>
                                <div className={`${classes.smallBox} ${classes.box4}`}>
                                    <div className={classes.inner}>
                                        <h3>{openings}</h3>
                                        <p>Job Openings</p>
                                    </div>
                                    <div className={classes.icon}>
                                        <MdWork />
                                    </div>
                                    <Link className={classes.smallBoxFooter} to={`/openings?org=${organizationID}`}>
                                        More info
                                        <FaArrowCircleRight className={classes.arrow} />
                                    </Link>
                                </div>
                            </div>

                            <div className={classes.box}>
                                <div className={`${classes.smallBox} ${classes.box5}`}>
                                    <div className={classes.inner}>
                                        <h3>{candidate}</h3>
                                        <p>Candidates</p>
                                    </div>
                                    <div className={classes.icon}>
                                        <FaRegUser />
                                    </div>
                                    <Link className={classes.smallBoxFooter} to={`/candidate?org=${organizationID}`}>
                                        More info
                                        <FaArrowCircleRight className={classes.arrow} />
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Col>
        </Row>

        {departments?.length > 0 && <Row>
            <Col xs="12">
                <div className="card">

                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Department View</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/department">Department</Link></li>
                                        <li className="breadcrumb-item active">Organization</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className={classes.cardTemplate}>

                            {departments?.map(data =>
                                <div className={classes.cardDiv}>
                                    <div className={classes.departmentName}>
                                        <h3 className="name">{data.name}</h3>
                                        <div className="tools float-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    caret
                                                    className="btn-icon"
                                                    color="link"
                                                    data-toggle="dropdown"
                                                    type="button"
                                                >
                                                    <BsThreeDotsVertical className={classes.dotsIcon} />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem
                                                        onClick={(e) => navigate('/department/AddEditDepartment/' + data.id)}
                                                    >
                                                        Edit Department
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        View Employees
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        className="text-danger"
                                                        onClick={(e) => {
                                                            departmentData.current = data
                                                            setShowModal("delete")
                                                        }}
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                    </div>
                                    <div className={classes.employeeData}>
                                        <BiUser className={classes.userIcon} />
                                        <label className={classes.employeeLabel}>
                                            <label className={classes.totalEmployees}>2000</label>
                                            <label className={classes.employees}>Employees</label>
                                        </label>
                                    </div>
                                    <div className={classes.pieChartDiv}>
                                        <PieChart
                                            data={[
                                                { title: 'New Employees', value: 350, color: '#f1c40f' },
                                                { title: 'Old Employees', value: 500, color: '#445cfb' },
                                                { title: 'Interns', value: 150, color: '#c1c9fa' },
                                            ]}
                                            label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
                                            labelStyle={defaultLabelStyle}
                                            className={classes.pieChart}
                                        />
                                        <label className={classes.labelList}>
                                            <div className={classes.oldEmployee}></div>
                                            <p>Old employees</p>
                                            <div className={classes.newEmployee}></div>
                                            <p>New employees</p>
                                            <div className={classes.interns}></div>
                                            <p>Interns</p>
                                        </label>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </Col>
            <Delete showModal={showModal === "delete"}
                setShowModal={setShowModal}
                DATA={departmentData.current}
                type={"Department"}
                module={DEPARTMENT}
            />
        </Row>}
        {/* 
        {dataView === "employee" && <Row>
            <Col xs="12">
                <div className="card">

                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Employees View</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/employee">Employee</Link></li>
                                        <li className="breadcrumb-item active">Organization</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className='employeeCardTemplate'>
                            <div className={classes.employeeCard}>

                                <div className={`card-header ${classes.employeeCardHeader}`}>
                                    <label className={classes.employeeDepartment}>Department</label>
                                    <label className={classes.employeeJoining}>DOJ : 2023-04-23</label>
                                    <div className="tools float-right">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                caret
                                                className="btn-icon"
                                                color="link"
                                                data-toggle="dropdown"
                                                type="button"
                                            >
                                                <BsThreeDotsVertical className={classes.dotsIcon} />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    Edit Employee
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    More Info
                                                </DropdownItem>
                                                <DropdownItem
                                                    className="text-danger"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    Deactivate
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className={`card-body ${classes.employeeCardBody}`}>
                                    <img src={defaultPicture} className={classes.EmployeePic} />

                                    <div className={classes.EmployeeNameDiv}>
                                        <label className={classes.EmployeeName}>Employee Name</label>
                                        ·
                                        <label className={classes.EmployeeID}>ID-1</label>
                                    </div>

                                    <div style={{ margin: "10px auto" }}>
                                        <label style={{ display: 'block' }}>email@gmail.com</label>
                                        <label>0123456789</label>
                                    </div>

                                    <div style={{ textAlign: '-webkit-center', marginBottom: "10px" }}>
                                        <div className={classes.roleDiv}>
                                            <label className={classes.role}>Role: CEO</label>
                                            <label className={classes.role}>Exp: 2 years</label>
                                        </div>
                                    </div>

                                    <div className={classes.salary}>
                                        Salary: Rs 200,000
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        } */}

    </>
}

export default App