const Joi = require("joi");
const PositionsSchema = Joi.object({
    id: Joi.allow(null),

    name: Joi.string().min(4).max(25).required().messages({
        "string.empty": `Please enter the Permision Name`,
        "string.min": `Permision Name Last name must be minimum 4 characters!`,
        "string.max": `Permision Name must be maximum 25 characters!`,
    }),
    hierarchy_level: Joi.number().required().messages({
        "number.base": `Please enter Level`,
        "string.empty": `Please enter Level`,
        "any.required": `Please enter Level`,
    }),

});

export default PositionsSchema;
