const Joi = require("joi");
const AddEditCandidateProfileSchema = Joi.object({
    id: Joi.allow(null),


    position: Joi.string().required().messages({
        "string.empty": `Please enter Position`,
    }),

    expected_salary: Joi.number().required().messages({
        "number.empty": `Please enter Salary`,
        "any.required": `Please enter Salary`,
    }),

});

export default AddEditCandidateProfileSchema;
