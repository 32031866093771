import { Button } from "reactstrap"
import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';
import React, { useEffect, useState } from 'react';
import Permission from '../../utils/functions/Permission'

const BulkDeleteButton = (props) => {
    const [loggedUser] = useAtom(loggedInUser)
    const [permission, setPermission] = useState()
    useEffect(() => {
        if (loggedUser?.user?.userType === "employee")
            Permission(loggedUser)
                .then((result) => {
                    setPermission(result.Permissions.find(data => data.name === (props.module?.toLowerCase() + "-delete")))
                })
    }, [loggedUser])
    return (
        (loggedUser?.user?.userType !== "employee" || permission) &&
        <Button
            color="warning"
            className="bulkDelete"
            onClick={() => props.setShowModal("Bulk-delete")}
        >
            Bulk Delete
        </Button>
    )
}
export default BulkDeleteButton