import React, { useState } from 'react'
import classes from '../../../assets/styles/employee.module.css'
import ValidateSchema from "../../../helpers/ValidateSchema";
import EmployeeExperienceSchema from '../../../utils/SchemasValidation/EmployeeExperienceSchema'
import ActionButtons from './ActionButtons'
import showToast from '../../../helpers/showToast'
import DataTable from 'react-data-table-component'
import customStyles from '../../../assets/styles/Table Styles/tableStyle'
import { FaTrashAlt } from "react-icons/fa"
import { BsPencilSquare } from "react-icons/bs"
import { deleteRecord } from '../../../server';
import { EMPLOYEE } from '../../../utils/constants/apiUrl';

const emptyExperience = {
    company_name: "",
    designation: "",
    from_date: "",
    to_date: "",
};

const ExperienceData = (props) => {
    const [EmployeeExpErrors, setEmployeeExpErrors] = useState({});

    const validate = async (e) => {
        props.setAddExperience(false)
        props.nextStep()
    }

    async function deleteExp(id) {
        let { status, message } = await deleteRecord(
            EMPLOYEE + "/experience/delete/" + id,
            Object.keys(props.loggedUser).length !== 0 ? props.loggedUser?.token : null
        )
        if (status)
            showToast(status, message)
    }

    const handleExperienceAdd = () => {
        let EexpErrors = ValidateSchema(props.employeeExperience, EmployeeExperienceSchema)
        setEmployeeExpErrors(EexpErrors)
        if (!EexpErrors.company_name && !EexpErrors.designation && !EexpErrors.from_date && !EexpErrors.to_date) {
            showToast(200, "Experience Added")
            props.setExperience([...props.experience, props.employeeExperience])
            props.setEmployeeExperience(emptyExperience)
            const newExperienceArray = [...props.employee.experience, props.employeeExperience];
            props.setEmployee({ ...props.employee, experience: newExperienceArray })
            props.setAddExperience(false)
        }
    };

    const handleEmployeExpChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setEmployeeExperience({ ...props.employeeExperience, [name]: value });
    };

    const experienceColumn = [
        {
            name: 'Company Name',
            selector: row => row.company_name,
            sortable: true
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.from_date.substring(0, 10),
            sortable: true
        },
        {
            name: 'End Date',
            selector: row => row.to_date?.substring(0, 10),
            sortable: true
        },
        {
            name: 'Action',
            button: true,
            cell: (row, index) => <div className={classes.actionIcons}>

                <FaTrashAlt
                    className={classes.infoIcon}
                    onClick={() => {
                        let newExp = [...props.employee.experience]
                        newExp.splice(index, 1)
                        props.setExperience(newExp)
                        props.setEmployee({ ...props.employee, experience: newExp })
                        deleteExp(row.id)
                    }}
                />
                <BsPencilSquare
                    className="edit"
                    onClick={() => {
                        const newRow = {
                            ...row,
                            from_date: new Date(row.from_date).toISOString().slice(0, 10),
                            to_date: new Date(row.to_date).toISOString().slice(0, 10),
                            index: index
                        }
                        props.setEmployeeExperience(newRow)
                        props.setAddExperience(true)
                    }}
                />
            </div>
        }
    ]

    return <>
        <section className={classes.expSection}>
            <button className={classes.AddButton}
                onClick={(e) => {
                    e.preventDefault()
                    if (props.AddExperience)
                        handleExperienceAdd(e)
                    else
                        props.setAddExperience(true)
                }}
            >
                Add Experience
            </button>

            {props.AddExperience === true && <div>
                <div className={classes.arrayGridview}>

                    <div className={classes.arrayview2}>
                        <div>
                            <label className='Starter'>Company Name*</label>
                            <input
                                value={props.employeeExperience.company_name}
                                className={EmployeeExpErrors?.company_name ? "form-control textInput errorInput " : "form-control textInput"}
                                onChange={handleEmployeExpChange}
                                type="text"
                                placeholder="Company Name"
                                name="company_name"
                            />
                            <p className="errorText">{EmployeeExpErrors?.company_name || ""}</p>
                        </div>

                        <div>
                            <label className='Starter'>Designation*</label>
                            <input
                                value={props.employeeExperience.designation}
                                className={EmployeeExpErrors?.designation ? "form-control textInput errorInput " : "form-control textInput"}
                                onChange={handleEmployeExpChange}
                                type="text"
                                placeholder="Designation"
                                name="designation"
                            />
                            <p className="errorText">{EmployeeExpErrors?.designation || ""}</p>
                        </div>

                        <div>
                            <label className='Starter'>Start Date*</label>
                            <input
                                value={props.employeeExperience.from_date}
                                className={EmployeeExpErrors?.from_date ? "form-control textInput errorInput " : "form-control textInput"}
                                onChange={handleEmployeExpChange}
                                type="date"
                                name="from_date"
                            />
                            <p className="errorText">{EmployeeExpErrors?.from_date || ""}</p>
                        </div>

                        <div>
                            <label className='Starter'>End Date*</label>
                            <input
                                value={props.employeeExperience.to_date}
                                className={EmployeeExpErrors?.to_date ? "form-control textInput errorInput " : "form-control textInput"}
                                onChange={handleEmployeExpChange}
                                type="date"
                                name="to_date"
                            />
                            <p className="errorText">{EmployeeExpErrors?.to_date || ""}</p>
                        </div>
                    </div>
                    <button
                        className={classes.InitialAddButton}
                        onClick={(e) => {
                            const newExperience = [...props.experience];
                            const index = props.employeeExperience.index
                            if (index >= 0) {
                                newExperience[index] = props.employeeExperience
                                props.setExperience(newExperience);
                                props.setEmployee({ ...props.employee, experience: newExperience })
                                props.setEmployeeExperience(emptyExperience)
                                showToast("ok", "Experience Updated")
                            }
                            else
                                handleExperienceAdd(e)
                            props.setAddExperience(false)
                            setEmployeeExpErrors({})
                        }}
                    >
                        Save
                    </button>
                    {/* <button
                        className={classes.InitialAddButton}
                        onClick={() => {
                            props.setAddExperience(false)
                            setEmployeeExpErrors({})
                        }}
                    >
                        Undo
                    </button> */}
                </div>
            </div>
            }
            <div className={classes.tableMargin}>
                <DataTable
                    columns={experienceColumn}
                    data={props.employee.experience}
                    highlightOnHover
                    responsive
                    striped
                    customStyles={customStyles}
                />
            </div>

            {/* {props.employee.experience?.map((e, index) => <div key={index} className={classes.arrayGridview}>

                <div className={classes.arrayview2}>
                    <div>
                        <label className='Starter'>Company Name</label>
                        <input
                            value={e.company_name}
                            className="form-control textInput"
                            onChange={(e) => handleExperienceChange(e, index)}
                            type="text"
                            placeholder="Twitter"
                            name="company_name"
                        />
                    </div>
                    <div>
                        <label className='Starter'>Designation</label>
                        <input
                            value={e.designation}
                            className="form-control textInput"
                            onChange={(e) => handleExperienceChange(e, index)}
                            type="text"
                            placeholder="Email ID"
                            name="designation"
                        />
                    </div>
                    <div>
                        <label className='Starter'>Start Date</label>
                        <input
                            value={e.from_date?.substr(0, 10)}
                            className="form-control textInput"
                            onChange={(e) => handleExperienceChange(e, index)}
                            type="date"
                            name="from_date"
                        />
                    </div>
                    <div>
                        <label className='Starter'>End Date</label>
                        <input
                            value={e.to_date?.substr(0, 10)}
                            className="textInput"
                            onChange={(e) => handleExperienceChange(e, index)}
                            type="date"
                            name="to_date"
                        />
                    </div>
                </div>

                <button
                    className={classes.RemoveButton}
                    onClick={(ee) => {
                        let newExp = [...props.employee.experience]
                        newExp.splice(index, 1)
                        props.setExperience(newExp)
                        props.setEmployee({ ...props.employee, experience: newExp })
                        showToast("ok", "Experience Removed")
                    }}
                >
                    Remove
                </button>

            </div>
            )} */}

            <div className={props.experience?.length < 1 && classes.nextDiv}>
                {/* <hr className={classes.hrClass} /> */}
                <br />
                <ActionButtons {...props} nextStep={validate} reset={props.setAddExperience} />
            </div>

        </section>
    </>
}

export default ExperienceData