import React, { useState } from 'react'
import classes from '../assets/styles/login.module.css'

import { ForgotPassword } from '../components/Modal/ForgotPassword'
import { USER } from '../utils/constants/apiUrl'
import { post } from "../server";
import { useNavigate } from "react-router-dom";
import showToast from '../helpers/showToast'
import { useAtom } from "jotai";
import { loggedInUser } from "../utils/store";
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import { Button } from 'reactstrap';

let emptyObj = {
    username: "",
    password: "",
};

function App() {
    const navigate = useNavigate()

    const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
    const [login, setLogin] = useState(emptyObj);
    const [modal, setModal] = useState('close')
    const [type, setType] = useState('password')

    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
        }
        else {
            setType('password')
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLogin({ ...login, [name]: value.trim() });
    };

    async function loginUser(e) {
        e.preventDefault()
        let { status, data, message } = await post(
            USER + "/login",
            login
        );
        if (status === 200) {
            if (data.user) setLoggedUser({ token: data.token, user: data.user })
            showToast(status, message)
            navigate('/')
        } else {
            showToast(status, message)
        }
    }

    return < >
        <form onSubmit={loginUser}
            className={classes.Logincontainer} >
            <h1 className={classes.Header}>Login</h1>
            <b>Username</b>
            <input
                value={login.username}
                className={classes.login}
                onChange={handleChange}
                type="username"
                placeholder="Username"
                name="username"
            /><br />
            <b>Password</b>
            <input
                value={login.password}
                className={classes.login}
                onChange={handleChange}
                type={type}
                placeholder="Password"
                name="password"
            />
            <span className={classes.eye}
                onClick={handleToggle}
            >
                {type === "password" ? <FaEye /> : <FaEyeSlash />}
            </span>
            <br />
            <div style={{ textAlign: '-webkit-center' }}>
                <Button
                    color="success"
                    className={classes.button}
                    type="submit"
                    onClick={loginUser}
                >Login</Button>
            </div>
            <br />
            <span
                onClick={() => {
                    setModal("forgot")
                }}
            >
                Forgot password?
            </span>
        </form>
        <ForgotPassword
            modal={modal === "forgot"}
            setModal={setModal}
        />
    </>
}

export default App;
