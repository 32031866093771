import React, { useState, useEffect, useRef } from 'react'
import classes from '../../assets/styles/Candidate.module.css'
import Select from 'react-select'
import { WithContext as ReactTags } from 'react-tag-input';
import { FaTrashAlt } from 'react-icons/fa'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditCandidateUserProfileSchema from '../../utils/SchemasValidation/AddEditCandidateUserProfileSchema'
import AddEditCandidateHistory from '../../utils/SchemasValidation/AddEditCandidateHistorySchema'
import AddEditCandidateProfile from '../../utils/SchemasValidation/AddEditCandidateProfile'
import { CANDIDATE, OPENING } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import { MdCloudUpload } from 'react-icons/md'
import { TbSlideshow } from 'react-icons/tb'
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";
import { Preview } from '../../components/Modal/Preview'

const KeyCodes = {
    enter: 13,
    tab: 9
};

const emptyCandidate = {
    first_name: "",
    last_name: "",
    phone_no: "",
    current_job_title: "",
    current_employer: "",
    experience_years: "",
    expected_salary: "",
    skillset: [],
    opening_id: "",
    department: "",
    position: "",
    employment: "",
    work_from: ""
}

const delimiters = [KeyCodes.enter, KeyCodes.tab];

const ActionButtons = (props) => {
    const { candidateID } = useParams()
    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };

    return (
        <div>
            <Row>
                {props.currentStep > 1 && (
                    <Col className="justify-content-start">
                        <Button
                            color='primary'
                            onClick={handleBack}
                            className="backButton"
                        >Back</Button>
                    </Col>
                )}
                <Col className="justify-content-end" >
                    {props.currentStep < props.totalSteps && (
                        <Button
                            color='primary'
                            onClick={handleNext}
                            className="nextButton"
                        >Next</Button>
                    )}
                    {props.currentStep === props.totalSteps && (
                        <Button
                            color='primary'
                            onClick={handleFinish}
                            className="nextButton"
                        >{candidateID ? "Update" : "Create"}</Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const UserProfile = (props) => {
    const [errors, setErrors] = useState({});
    const validate = () => {
        let Errors = ValidateSchema(props.candidate, AddEditCandidateUserProfileSchema)
        setErrors(Errors)
        if (Object.keys(Errors).length) return false;
        else {
            props.nextStep();
        }
        return true;
    };

    return <>
        <div className={classes.CandidateView}>

            <div>
                <label className="Starter">First Name</label>
                <input
                    value={props.candidate.first_name}
                    className={errors?.first_name ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="text"
                    placeholder="Elon"
                    name="first_name"
                />
                <p className="errorText">{errors?.first_name || ""}</p>
            </div>

            <div>
                <label className="Starter">Last Name</label>
                <input
                    value={props.candidate.last_name}
                    className={errors?.last_name ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="text"
                    placeholder="Musk"
                    name="last_name"
                />
                <p className="errorText">{errors?.last_name || ""}</p>
            </div>

            <div>
                <label className="Starter">Number</label>
                <input
                    value={props.candidate.phone_no}
                    className={errors?.phone_no ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="tel"
                    placeholder="Number"
                    name="phone_no"
                />
                <p className="errorText">{errors?.phone_no || ""}</p>
            </div>

            <div>
                <label className="Starter">Email</label>
                <input
                    value={props.candidate.email}
                    className={errors?.email ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="email"
                    placeholder="Email ID"
                    name="email"
                />
                <p className="errorText">{errors?.email || ""}</p>
            </div>
        </div>
        <br />
        <ActionButtons {...props} nextStep={validate} />
    </>
}

const History = (props) => {
    const [errors, setErrors] = useState({});
    const validate = () => {
        let Errors = ValidateSchema(props.candidate, AddEditCandidateHistory)
        setErrors(Errors)
        if (Object.keys(Errors).length) return false;
        else {
            props.nextStep();
        }
        return true;
    };

    return <>
        <div className={classes.CandidateView}>
            <div>
                <label className="Starter">Current Job Title</label>
                <input
                    value={props.candidate.current_job_title}
                    className={errors?.current_job_title ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="text"
                    placeholder="Current Job Title"
                    name="current_job_title"
                />
                <p className="errorText">{errors?.current_job_title || ""}</p>
            </div>

            <div>
                <label className="Starter">Current Employer/Company</label>
                <input
                    value={props.candidate.current_employer}
                    className={errors?.current_employer ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="text"
                    placeholder="Employer/Company"
                    name="current_employer"
                />
                <p className="errorText">{errors?.current_employer || ""}</p>
            </div>

            <div>
                <label className="Starter">Experience</label>
                <input
                    value={props.candidate.experience_years}
                    className={errors?.experience_years ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="number"
                    placeholder="Years"
                    name="experience_years"
                />
                <p className="errorText">{errors?.experience_years || ""}</p>
            </div>

            <div>
                <label className="Starter">Skill Set</label>
                <ReactTags
                    tags={props.skillsetA}
                    delimiters={delimiters}
                    handleDelete={props.handleDelete}
                    handleAddition={props.handleAddition}
                    // handleTagClick={props.setTagIndex}
                    // handleInputChange={props.handleEdit}
                    inputFieldPosition="inline"
                    // editable
                    allowDragDrop={false}
                    placeholder="Add Skills"
                    classNames={
                        {
                            tags: {
                                "rti--container": true,
                                "rti--container errorInput": errors?.skillset
                            },
                            tagInput: "rti--input",
                            tagInputField: classes.inputField,
                            tag: "rti--tag",
                            editTagInputField: classes.inputField
                        }
                    }
                />
                <p className="errorText">{errors?.skillset || ""}</p>
            </div>
        </div>
        <br />
        <ActionButtons {...props} nextStep={validate} />
    </>
}

const CandidateProfile = (props) => {
    const [showModal, setShowModal] = useState("close")
    const previewData = useRef()
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [errors, setErrors] = useState({});
    const validate = () => {
        let Errors = ValidateSchema(props.candidate, AddEditCandidateProfile)
        setErrors(Errors)
        if (Object.keys(Errors).length) return false;
        else {
            props.lastStep();
            props.completeCallback();
        }
        return true;
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFiles([...files, selectedFile]);
    };

    return <>
        <div className={classes.CandidateView}>

            <div>
                <label className="Starter">Select Job</label>
                <Select
                    options={props.openingOptions}
                    value={props.opening}
                    placeholder="Select Opening"
                    onChange={(e) => {
                        props.setOpening(e)
                        props.setCandidate({
                            ...props.candidate,
                            opening_id: e.value,
                            department: e.department,
                            position: e.position,
                            employment: e.employment,
                            work_from: e.work_from
                        })
                    }}
                    className={errors?.opening ? "errorInput " : ""}
                />
                <p className="errorText">{errors?.opening || ""}</p>
            </div>

            <div>
                <label className="Starter">Department</label>
                <input
                    value={props.candidate?.department}
                    className="form-control textInput"
                    type="text"
                    disabled
                />
            </div>

            <div>
                <label className="Starter">Position</label>
                <input
                    value={props.candidate?.position}
                    className="form-control textInput Captalized"
                    type="text"
                    disabled
                />
            </div>

            <div>
                <label className="Starter">Employment Type</label>
                <input
                    value={props.candidate.employment}
                    className="form-control textInput Captalized"
                    type="text"
                    disabled
                />
            </div>

            <div>
                <label className="Starter">Work From</label>
                <input
                    value={props.candidate.work_from}
                    className="form-control textInput Captalized"
                    type="text"
                    disabled
                />
            </div>

            <div>
                <label className="Starter">Expected Salary</label>
                <input
                    value={props.candidate.expected_salary}
                    className={errors?.expected_salary ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={props.handleChange}
                    type="tel"
                    placeholder="Salary"
                    name="expected_salary"
                />
                <p className="errorText">{errors?.expected_salary || ""}</p>
            </div>

            <br />
        </div>
        <div className={classes.resume}>
            <label className="Starter">Resume</label>
            <div className='alignCenter'>
                <div className={classes.uploadBackground}>
                    <MdCloudUpload className={classes.uploadIcon} />
                </div>
                <br />
                <input type="file"
                    multiple
                    onChange={handleFileChange}
                    accept="image/*, application/pdf"
                    className={classes.fileUploader}
                    ref={fileInputRef}
                />
                <Button
                    color={"default"}
                    className={"default"}
                    onClick={() => handleClick()}
                >
                    Select Files
                </Button>
            </div>

        </div>


        {files.length > 0 && <div className={classes.previewImages}>
            {files.map((file, index) => {
                return (
                    <div style={{ width: '100px' }}>
                        {file.type.startsWith('image/') ? (
                            <div>
                                <img src={URL.createObjectURL(file)} alt="File preview" height="120px" width="100px" />
                            </div>
                        ) : (
                            <iframe src={URL.createObjectURL(file)} title="File preview" height="100px" width="100px" />
                        )}
                        <div className={classes.previewIcons}>

                            <TbSlideshow
                                className={classes.previewIcon}
                                onClick={() => {
                                    setShowModal("preview")
                                    previewData.current = file
                                }}
                            />
                            <FaTrashAlt
                                onClick={(ee) => {
                                    let newFiles = [...files]
                                    newFiles.splice(index, 1)
                                    setFiles(newFiles)
                                }}
                                className={classes.deleteIcon}
                            />
                        </div>
                    </div>
                )
            })}
        </div>}
        <br />
        <Preview
            showModal={showModal === "preview"}
            setShowModal={setShowModal}
            file={previewData.current}
        />
        <ActionButtons {...props} lastStep={validate} />
    </>
}

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const navigate = useNavigate()
    const { candidateID } = useParams()

    const [candidate, setCandidate] = useState(emptyCandidate)

    useEffect(() => {
        loadOpenings()
    }, [])

    const [skillsetA, setSkillsetA] = useState([])
    const [opening, setOpening] = useState()
    const [openingOptions, setOpeningOptions] = useState([])

    async function loadOpenings() {
        let { status, data } = await get(
            OPENING + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const names = data?.map((values) => ({
                value: values.id,
                label: values.name,
                department: values.Department?.name,
                position: values.Position?.name,
                employment: values.employment,
                work_from: values.workFrom,
            }))
            setOpeningOptions(names)
        }
    }

    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFiles([...files, selectedFile]);
    };
    const [tagIndex, setTagIndex] = useState()
    const handleAddition = tag => {
        setSkillsetA([...skillsetA, tag])
        setCandidate({
            ...candidate,
            skillset: [...candidate.skillset, tag.text]
        })
    };

    const handleDelete = (i) => {
        setSkillsetA(skillsetA.filter((tag, index) => index !== i));
        const skills = skillsetA.map(obj => obj.text).filter((tag, index) => index !== i)
        setCandidate({
            ...candidate,
            skillset: skills.join(',')
        })
    };

    const handleEdit = (inputValue,) => {
        const tags = [...skillsetA];
        if (tagIndex !== -1) {
            tags[tagIndex] = { id: inputValue, text: inputValue };
            if (delimiters)
                setSkillsetA(tags);
            setCandidate({ ...candidate, skillset: tags })
        }
    };

    async function loadCandidate() {
        let { status, data } = await get(
            CANDIDATE + "/id/" + candidateID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const { firstName, lastName, email, phoneNo,
                currentJobTitle, currentEmployer, experienceYears, skillSet,
                expectedSalary, jobOpeningId } = data
            const skills = skillSet.split(",")
            setCandidate({
                first_name: firstName,
                last_name: lastName,
                email,
                phone_no: phoneNo,
                current_job_title: currentJobTitle,
                current_employer: currentEmployer,
                experience_years: experienceYears,
                skillset: skills,
                expected_salary: expectedSalary,
                opening_id: jobOpeningId
            })
            setSkillsetA(skills.map((tag) => ({ id: tag, text: tag })))
            // const skillSets = skills.map((tag) => ({ id: tag, text: tag }))
            // setSkillsetA(skillSets)
            // setOpening({ value: data.position_id, label: data.opening_name })
        }
    }

    useEffect(() => {
        const opening = openingOptions?.find(items => items.value === candidate.opening_id)
        setOpening(opening)
        setCandidate({
            ...candidate,
            department: opening?.department,
            position: opening?.position,
            employment: opening?.employment,
            work_from: opening?.work_from
        })
    }, [candidate.opening_id, openingOptions])

    useEffect(() => {
        if (candidateID) loadCandidate()
    }, [])

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCandidate({ ...candidate, [name]: value });
    };

    const handleSubmit = async (e) => {
        const skillString = candidate.skillset.join(", ")
        let tempCandidate = {
            ...candidate,
            skill_set: skillString
        }
        delete tempCandidate.skillset
        delete tempCandidate.department
        delete tempCandidate.position
        delete tempCandidate.work_from
        delete tempCandidate.employment
        let { status, message } = await post(
            CANDIDATE + "/addorUpdate" + (candidateID ? `/${candidateID}` : ""),
            tempCandidate,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            navigate("/candidate");
        }
        showToast(status, message);
    };

    const [showModal, setShowModal] = useState(false);

    const handlePreviewClick = () => {
        setShowModal(true);
    };

    const [activeStep, setActiveStep] = useState(0);
    const [stepWizard, setStepWizard] = useState(null);
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    function handleStepClick(step) {
        setActiveStep(step);
        stepWizard.goToStep(step + 1);
    }

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{candidateID ? "Update" : "Create New"} Candidate</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/candidate">Candidates</Link></li>
                                        <li className="breadcrumb-item active">Candidate Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <Stepper
                            activeStep={activeStep}
                            connectorStyleConfig={
                                {
                                    activeColor: '#343a40',
                                    completedColor: "#24415e",
                                    size: 2,

                                }
                            }
                            styleConfig={{
                                activeBgColor: '#343a40',
                                completedBgColor: "rgb(46 127 209)",
                                labelFontSize: "20px",
                                size: "2.5em"
                            }}
                        >
                            <Step label="User Details" children={" "} id="0" onClick={() => handleStepClick(0)} />
                            <Step label="Previous History" children=" " id="1" onClick={() => handleStepClick(1)} disabled={candidateID && false} />
                            <Step label="Candidate Profile" children=" " id="2" onClick={() => handleStepClick(2)} disabled={candidateID && false} />
                        </Stepper>
                        <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                            <UserProfile
                                candidate={candidate}
                                handleChange={handleChange}
                            />
                            <History
                                candidate={candidate}
                                handleChange={handleChange}
                                skillsetA={skillsetA}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleEdit={handleEdit}
                                setTagIndex={setTagIndex}
                            />

                            <CandidateProfile
                                candidate={candidate}
                                setCandidate={setCandidate}
                                opening={opening}
                                openingOptions={openingOptions}
                                setOpening={setOpening}
                                handleChange={handleChange}
                                files={files}
                                handlePreviewClick={handlePreviewClick}
                                handleFileChange={handleFileChange}
                                completeCallback={handleSubmit}
                            />
                        </StepWizard>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    </ >
}

export default App;