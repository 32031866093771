import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom';
import classes from '../../assets/styles/employee.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { GrContactInfo } from 'react-icons/gr'
import { EMPLOYEE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { get, post } from "../../server";
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import CreationButton from '../../components/Global Components/CreationButton';
import ActiveButtons from '../../components/Global Components/ActiveButtons';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import EditAction from '../../components/Global Components/EditAction';
import { BiRefresh } from 'react-icons/bi';
import { Button } from 'reactstrap';

const Employees = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const location = useLocation()
    const org = new URLSearchParams(location.search).get('org');
    const position = new URLSearchParams(location.search).get('position');

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [query, setQuery] = useState("")
    // const [showModal, setShowModal] = useState("close")
    const [select, setSelect] = useState(false)
    const userData = useRef()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        if (org) {
            setOrganization(organizationOptions.find(data => data.value == org))
            setDATA(data?.filter(data => data.organizationId == org))
        }
    }, [org, organizationOptions, data])

    useEffect(() => {
        if (position && org && data) {
            handleQuery(position)
        }
    }, [position, org, data])

    async function loadEmployee(page, size = perPage) {
        let { status, data, message } = await get(
            EMPLOYEE + '/listing?page=' + page + "&perPage=" + size,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setTotalRows(data.totalItems)
            setData(data.data)
            setDATA(data.data)
        } else if (status === 401)
            showToast(status, message)
    }
    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadEmployee(currentPage, perPage)
    }, [currentPage, perPage])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        userData.current = selectedRows.map(row => row.id)
    }

    const activate = (e) => {
        bulkStatus(userData.current, "active")
    }

    const Deactivate = (e) => {
        bulkStatus(userData.current, "inactive")
    }

    async function changeStatus(id, e) {
        const value = { status: e.target.value }
        let { status, message } = await post(
            EMPLOYEE + "/change/status/" + id,
            value,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status)
            showToast(status, message)
    }

    async function bulkStatus(items, userStatus) {
        let { status, message } = await post(
            EMPLOYEE + "/bulk/change/status",
            { status: userStatus, ids: items },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadEmployee()
        }
    }

    const columns = [
        {
            name: 'Username',
            selector: row => row.User?.username,
            sortable: true
        },
        {
            name: 'Name',
            selector: row => row.User?.firstName,
            sortable: true,
            cell: (row) => <div>
                {row.User?.firstName} {row.User?.lastName}
            </div >
        },
        {
            name: 'Email',
            selector: row => row.User?.email,
            sortable: true
        },
        {
            name: 'Position',
            selector: row => row.Position?.name,
            sortable: true
        },
        {
            name: 'Department',
            selector: row => row.Department?.name,
            sortable: true
        },
        {
            name: 'Salary',
            selector: row => row.annualSalary,
            sortable: true,
            button: true,
            cell: (row) => <div>
                Rs. {row.annualSalary}
            </div >

        },
        {
            name: 'Status',
            selector: row => row.User?.userStatus,
            button: true,
            width: "120px",
            cell: row => <div>
                {loggedUser?.user?.userType !== "Employee" ? <select
                    name="status"
                    className="dropDown"
                    // value={row.status}
                    defaultValue={row.User?.userStatus}
                    onChange={(e) => {
                        changeStatus(row.id, e)
                    }}
                >
                    <option value="active" className="dropdownOptions">Active</option>
                    <option value="inactive" className="dropdownOptions">Inactive</option>
                </select> : <div>{row.status} </div>}
            </div>

        },
        {
            name: "Action",
            selector: row => row.action,
            button: true,
            width: '150px',
            cell: (row) => <div className={classes.actionIcons}>
                <Link to={`/employee/info/${row.id}`}
                >
                    <GrContactInfo
                        className={classes.infoIcon}
                    />
                </Link>
                <EditAction
                    link={"/employee/AddEditEmployee"}
                    id={row.id}
                    module={"Employees"}
                />
            </div>
        },
    ]
    const [filteredData, setFilteredData] = useState()

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.User.firstName.toLowerCase().includes(query) ||
            dat.User.username.toLowerCase().includes(query) ||
            dat.Department.name.toLowerCase().includes(query) ||
            dat.Position?.name.toLowerCase().includes(query) ||
            dat.Position?.name.includes(query)
        ))
    }, [query])

    const handleQuery = (filter) => {
        const orgFilter = data?.filter(data => data.organizationId == org)
        setFilteredData(orgFilter?.filter(dat => dat.User.firstName.toLowerCase().includes(filter) ||
            dat.User.username.toLowerCase().includes(filter) ||
            dat.Department.name.toLowerCase().includes(filter) ||
            dat.Position?.name.toLowerCase().includes(filter) ||
            dat.Position?.name.includes(filter)
        ))
        setQuery(filter)
    }

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.User.firstName.toLowerCase().includes(query) ||
                    dat.User.username.toLowerCase().includes(query) ||
                    dat.Department.name.toLowerCase().includes(query)))
        }
        else {
            const filtered = data.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.User.firstName.toLowerCase().includes(query) ||
                    dat.User.username.toLowerCase().includes(query) ||
                    dat.Department.name.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    return <>
        <div className='card'>
            <div className="card-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Employee View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Employee</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="card-body">
                <CreationButton link={"/employee/AddEditEmployee"} module="Employees" />
                {select && <ActiveButtons
                    activate={activate}
                    Deactivate={Deactivate}
                    module={"Employees"}
                />}
                <Button color="success" className='create' onClick={() => { setPerPage(10); setCurrentPage(1) }}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>

                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                {loggedUser?.user?.userType === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                    paginationServer={true}
                    totalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                />

            </section>
        </div>
    </>
}

export default Employees