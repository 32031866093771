import React from 'react'
import Dropdown from 'react-dropdown';
import { AiOutlineCloseCircle } from 'react-icons/ai'

export const Attendance = ({ showModal, setShowModal, data }) => {
    const options = ["Present", "Absent"]

    return <>{data !== undefined ? showModal ? <div className='backgroundBlur' >
        <div className='AttendanceModal'>
            <AiOutlineCloseCircle className='closemodalbutton'
                onClick={() => {
                    setShowModal("close")
                }} />
            <div style={{ padding: '15px' }}>
                <h3 className='Header'>Mark Attendance of {data.length}
                    {data.length === 1 ? " Employee" : " Employees"}</h3>
                <br />
                <Dropdown
                    options={options}
                />
                <br />
                <button className="SaveButton" style={{ float: 'none', }}
                // onClick={(e) => BulkDeleteUsers(DATA, setShowModal, setCheck)}
                >Save</button>
                <br />
            </div>
        </div>
    </div> : null : null
    }</>

}