import baseUrl from "./baseUrl";

const API_BASE = baseUrl.BASE_URL;
const PUBLIC_URL = baseUrl.PUBLIC_URL;

export const USER = PUBLIC_URL + 'auth'
export const ORGANIZATION = PUBLIC_URL + 'organization'
export const DEPARTMENT = PUBLIC_URL + 'department'
export const PROJECT = PUBLIC_URL + 'project'
export const EMPLOYEE = PUBLIC_URL + 'employee'
export const CANDIDATE = PUBLIC_URL + 'candidate'
export const OPENING = PUBLIC_URL + 'opening'
export const POSITION = PUBLIC_URL + 'position'
export const ROLE = PUBLIC_URL + 'role'
export const CRITERIA = PUBLIC_URL + 'criteria'
export const LEAVE = PUBLIC_URL + 'leave'
export const ATTENDANCE = PUBLIC_URL + 'attendance'