import React, { useEffect, useState, useRef } from 'react'
import showToast from '../../helpers/showToast'
import classes from '../../assets/styles/clockIn.module.css'
import { get, post } from '../../server'
import { ATTENDANCE } from '../../utils/constants/apiUrl'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import TableComponent from '../../components/Global Components/DataTable'
import { Dispute } from '../../components/Modal/ClockInDispute'
import { Button, Col, Row, Card, CardBody, CardHeader, Nav, NavItem } from 'reactstrap'
import { Link, NavLink } from 'react-router-dom'
import { BiRefresh } from 'react-icons/bi'

const emptyObj = {
    id: "",
    date: new Date().toISOString().substring(0, 10),
    clockIn: "",
    clockOut: "",
    hours: "",
    employeeId: ""
}
const App = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const [clock, setClock] = useState(true)
    const [data, setData] = useState([])
    const [dataToday, setDataToday] = useState(emptyObj)
    const [modal, setModal] = React.useState(false);
    const employeeData = useRef()
    let time = new Date().toLocaleTimeString()
    const [currentTime, setCurrentTime] = useState(time)
    const updateTime = () => {
        const options = { hour12: false };
        let time = new Date().toLocaleTimeString(undefined, options)
        setCurrentTime(time)
    }

    setInterval(updateTime, 1000)
    let day = new Date().toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })

    async function getEmployee() {
        let { status, data } = await get(
            ATTENDANCE + "/clockInOut/id",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            setData(data.ClockInOuts)
            const theData = data.ClockInOuts.find(dat => dat.date.slice(0, 10) === dataToday.date)
            if (theData) {
                setDataToday(theData)
            }
        }
    }

    useEffect(() => {
        getEmployee()
    }, [])

    useEffect(() => {
        getEmployee()
    }, [modal])

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            button: true,
        },
        {
            name: 'Date',
            selector: row => row.date.slice(0, 10),
            sortable: true,
            center: true,
        },
        {
            name: 'Clock IN',
            selector: row => row.clockIn,
            center: true,
        },
        {
            name: 'Clock Out',
            selector: row => row.clockOut,
            center: true,
        },
        {
            name: 'Total Hrs',
            selector: row => row.hours,
            button: true
        },
        {
            name: "Action",
            center: true,
            cell: row => <Button
                className={`btn-simple ${classes.disputeButton}`}
                color="google"
                onClick={() => handleAction(row)}
            >
                Dispute
            </Button>
            // cell: row => loadDisputes(row)
        }
    ]

    const handleAction = (row) => {
        employeeData.current = row
        toggleModal()
    }

    const toggleModal = () => {
        setModal(!modal);
    };

    async function handleClockInOut(action) {
        let { status, message } = await post(
            ATTENDANCE + "/clockInOut/add",
            { action, currentTime },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            showToast(status, message)
            if (action === "clock_in")
                setDataToday({ ...dataToday, clockIn: currentTime })
            else
                setDataToday({ ...dataToday, clockOut: currentTime })
            getEmployee()
        }
    }

    useEffect(() => {
        if (!clock) {
            const newData = [...data]
            const index = dataToday.id
            newData[index - 1] = dataToday
            setData(newData)
            setClock(true)
        }
    }, [clock, data, dataToday])

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Clock In & Out</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/home">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Clock In & Out</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className={`${classes.centeredItem} ${classes.clock}`}>
                            <br />
                            <h2>{currentTime}</h2>
                            <h2>{day}</h2>
                            {(!dataToday.clockIn || !dataToday.clockOut) && <hr className={classes.hrStyle} />}
                            {!dataToday.clockIn &&
                                <Button
                                    color='info'
                                    className={classes.clockIn}
                                    onClick={() => handleClockInOut("clock_in")}
                                >Clock In</Button>
                            }
                            {(dataToday.clockIn && !dataToday.clockOut) &&
                                <Button
                                    color="danger"
                                    className={classes.clockOut}
                                    onClick={() => handleClockInOut("clock_out")}
                                >Clock Out</Button>
                            }
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">History</h2>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Button color="success" className='create' onClick={() => getEmployee()}>
                            <BiRefresh className='buttonIcons' />
                            Refresh
                        </Button>
                        <TableComponent
                            columns={columns}
                            Data={data}
                            allcheck={false}
                        />
                        <Dispute
                            modal={modal}
                            data={employeeData.current}
                            toggleModal={toggleModal}
                            loggedUser={loggedUser}
                        />

                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}
export default App