import React, { useState, useEffect } from 'react'
import classes from '../../assets/styles/project.module.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditProjectSchema from '../../utils/SchemasValidation/AddEditProjectSchema'
import { DEPARTMENT, PROJECT, EMPLOYEE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { loadOrganization } from '../../helpers/apis'
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';

const animatedComponents = makeAnimated()

let emptyProject = {
    name: "",
    department_ids: [],
    permission_ids: []
};


const App = () => {
    const { projectID } = useParams()
    const navigate = useNavigate()
    const [loggedUser] = useAtom(loggedInUser)

    //project
    const [project, setProject] = useState(emptyProject)
    const [departmentOptions, setdepartmentOptions] = useState([])
    const [AllDepartments, setAllDepartments] = useState([])
    const [departments, setDepartments] = useState([])
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [organization, setOrganization] = useState([])
    const [leadOptions, setLeadOptions] = useState()
    const [lead, setLead] = useState()
    const [role, setRole] = useState()
    const [position, setPosition] = useState()

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
        if (loggedUser?.position) setPosition(loggedUser?.position)
    }, [loggedUser])

    useEffect(() => {
        if (position !== "Lead") loadLeads()
        loadDepartment()
        loadOrganization(loggedUser, setOrganizationOptions)
    }, [position])

    async function loadDepartment() {
        let { status, data } = await get(
            DEPARTMENT + `/listing`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const names = data.map((department) => ({
                value: department.id,
                label: department.name,
                organization_id: department.organizationId
            }))
            if (loggedUser?.user?.userType !== "super_admin") setdepartmentOptions(names)
            setAllDepartments(names)
        }
    }
    async function loadLeads() {
        let { status, data } = await get(
            EMPLOYEE + `/leads/get`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === "ok") {
            setLeadOptions(data?.map(({ id, first_name, last_name }) => ({
                value: id,
                label: first_name + " " + (last_name === null ? "" : last_name)
            })))
        }
    }

    useEffect(() => {
        setdepartmentOptions(AllDepartments?.filter(dat => dat.organization_id === organization.value))
    }, [organization])

    async function loadProject() {
        let { status, data } = await get(
            PROJECT + `/id/${projectID}`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const { name, organizationId } = data
            // setDepartments({ value: department_id, label: department_name })
            setOrganization({ value: data.Organization.id, label: data.Organization.name })
            setProject({
                name,
                organization_id: organizationId
            })
            const dep = data.Departments.map(rows => ({ value: rows.id, label: rows.name }))
            setDepartments(dep)
        }
    }

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let Errors = ValidateSchema(project, AddEditProjectSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setProject({ ...project, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        let { status, message } = await post(
            PROJECT + "/addOrUpdate" + (projectID ? `/${projectID}` : ""),
            project,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            navigate("/project");
        }
        showToast(status, message);
    }

    useEffect(() => {
        if (projectID) loadProject();
    }, [])

    const handleSelectChange = (selectedOptions) => {
        const selectedDepartments = selectedOptions.map((option) =>
            option.value
        );
        setDepartments(selectedOptions)
        setProject({
            ...project, department_ids: selectedDepartments
        })
    };

    const handleLeadChange = (selectedOptions) => {
        const selectedLeads = selectedOptions.map((option) => ({
            lead_id: option.value,
            lead_name: option.label,
        }));

        setLead(selectedOptions)
        setProject({
            ...project, leads: selectedLeads
        })
    };

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{projectID ? "Update " : "Create new "}Project</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/project">Project</Link></li>
                                        <li className="breadcrumb-item active">Project Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Project Data</h2>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.View}>

                            <div>
                                <label className="Starter">
                                    Project Name*
                                </label>
                                <input
                                    value={project.name}
                                    className={errors?.name ? "form-control textInput errorInput" : "form-control textInput"}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Project Name"
                                    name="name"
                                />
                                <p className="errorText">{errors?.name || ""}</p>
                            </div>

                            {role === "super_admin" && <div>
                                <label className="Starter">
                                    Organization*
                                </label>
                                <Select
                                    options={organizationOptions}
                                    value={organization}
                                    onChange={(e) => {
                                        setOrganization(e)
                                        setProject({ ...project, organization_id: e.value })
                                    }}
                                    name="organization_id"
                                    className={errors?.organization_name ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.organization_id || ""}</p>
                            </div>}

                            <div>
                                <label className="Starter">Departments</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={departmentOptions}
                                    value={departments}
                                    onChange={handleSelectChange}
                                    // onChange={(e) => setProject({ ...project, department_id: e.value, department_name: e.label })}
                                    className={errors?.departments ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.departments || ""}</p>
                            </div>

                            {position !== "Lead" && <div>
                                <label className="Starter">
                                    Project Lead
                                </label>
                                <Select
                                    options={leadOptions}
                                    value={lead}
                                    isMulti={true}
                                    onChange={handleLeadChange}
                                    name="lead"
                                    className={errors?.leads ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.leads || ""}</p>
                            </div>
                            }

                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className="rightItem">
                            <Button
                                color='info'
                                className="Createbutton"
                                onClick={handleSubmit}
                            >
                                {projectID ? "Update " : "Create "}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    </ >
}

export default App;