import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/settings.module.css'
import Select from 'react-select'
import { loadOrganization } from '../helpers/apis'
import { useAtom } from "jotai";
import { loggedInUser } from "../utils/store";
import { get, post } from "../server";
import { LEAVE, ORGANIZATION, POSITION } from '../utils/constants/apiUrl'
import TableComponent from '../components/Global Components/DataTable';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import showToast from '../helpers/showToast';

const emptySetting = {
    organization_id: "",
    reporters: []
}

const App = () => {

    const [settings, setSettings] = useState(emptySetting)
    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [loggedUser] = useAtom(loggedInUser)
    const [role, setRole] = useState()
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [settingsList, setSettingsList] = useState([])

    useEffect(() => { setRole(loggedUser?.user?.userType) }, [loggedUser])

    async function loadSettings() {
        let { status, data } = await get(
            LEAVE + "/setting/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setSettingsList(data)
        }
    }

    async function loadPositions() {
        let { status, data } = await get(
            POSITION + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200)
            setData(data)
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadPositions()
        loadSettings()
    }, [])


    useEffect(() => {
        if (loggedUser?.user.userType === "organization") {
            let highestLevel = 0;
            data?.forEach(position => {
                if (position.hierarchyLevel > highestLevel) {
                    highestLevel = position.hierarchyLevel;
                }
            });
            const updatedPositions = data?.filter(position => position.hierarchyLevel !== highestLevel);
            const newData = updatedPositions?.map((rows) => {
                const isChecked = settingsList[0]?.Leave_Criteria_Positions?.some(list => rows.id === list.positionId)
                return { ...rows, check: isChecked }
            })
            setDATA(newData)
        }
    }, [loggedUser, data, settingsList])

    useEffect(() => {
        if (organization) {
            const templist = settingsList?.find(settings => settings.organizationId === organization?.value)
            const newData = Data?.map((rows) => {
                const isChecked = templist?.Leave_Criteria_Positions.some(list => rows.id === list.positionId)
                return { ...rows, check: isChecked }
            })
            setDATA(newData)
        }
    }, [organization, settingsList])

    const handleCheck = (e, row) => {
        const { checked } = e.target;
        const newData = [...Data]
        if (checked)
            setSettings({
                ...settings,
                reporters: [...settings.reporters, row.id]
            })

        if (!checked) {
            const updatedReporters = settings.reporters.filter((id) => id !== row.id);
            setSettings({
                ...settings,
                reporters: updatedReporters
            });
        }
        newData.map(data => {
            if (row.id === data.id)
                data.check = checked
        })
        setDATA(newData)
    }

    const columns = [
        {
            name: "Position",
            center: true,
            selector: row => row.name,
        },
        {
            name: "Action",
            center: true,
            cell: row => <input
                type="checkbox"
                checked={row.check}
                name={row.id}
                onChange={(e) => handleCheck(e, row)}
            />
        }
    ]

    const handleOrgData = (selectedOption) => {
        setSettings({ ...settings, organization_id: selectedOption?.value })
        const filtered = data.filter(dat => dat.organizationId === selectedOption?.value)
        let highestLevel = 0;
        filtered.forEach(position => {
            if (position.hierarchyLevel > highestLevel) {
                highestLevel = position.hierarchyLevel;
            }
        });
        const updatedPositions = filtered.filter(position => position.hierarchyLevel !== highestLevel);
        setDATA(updatedPositions)
        setOrganization(selectedOption)
    }


    useEffect(() => {
        if (settings.organization_id !== organization?.value)
            setSettings({ ...settings, reporters: [] })
    }, [organization, settings.organization_id])

    async function handleSave() {
        let { status, message } = await post(
            LEAVE + "/setting/addOrUpdate",
            settings,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadSettings()
        }
    }

    return <>
        <Card>
            <CardHeader>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Leave Settings Criteria</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Leave Criteria</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </CardHeader>
        </Card>

        <Card>
            <CardBody>
                {role === "super_admin" && <div className={classes.selector}>
                    <Select
                        options={organizationOptions}
                        value={organization}
                        isClearable={true}
                        placeholder="Select Organization"
                        onChange={handleOrgData}
                        name="organization_id"
                    />
                </div>}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    pagination={false}
                    message={organization ? "No data to show" : "Please Select Organization to view data."}
                    allcheck={false}
                />
            </CardBody>
        </Card>
        {Data?.length && <Card>
            <CardBody>

                <div className="rightItem">
                    <Button
                        color='info'
                        className="Createbutton"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </CardBody>
        </Card>
        }
    </>
}

export default App;