const Joi = require("joi");
const AddEditCriteriaSchema = Joi.object({
    id: Joi.allow(null),
    total_day: Joi.number().min(1).required().messages({
        "number.min": `Please enter Totale Late Comings`,
    }),
    percentage_deduction: Joi.number().min(1).required().messages({
        "number.min": `Please enter the Deduction Pecentage`,
    }),

});

export default AddEditCriteriaSchema;
