import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select';
import 'react-dropdown/style.css';
import classes from '../../assets/styles/Candidate.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";

import { CANDIDATE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { get, post } from "../../server";
import CreationButton from '../../components/Global Components/CreationButton';
import TableFilter from '../../components/Global Components/TableFilter';
import { Card, CardBody, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { Link, useLocation } from "react-router-dom";
import TableComponent from '../../components/Global Components/DataTable';
import { GrContactInfo } from 'react-icons/gr';
import EditAction from '../../components/Global Components/EditAction';
import DeleteAction from '../../components/Global Components/DeleteAction';
import { Delete } from '../../components/Modal/Delete';
import BulkDeleteButton from '../../components/Global Components/BulkDelete';
import { BulkDelete } from '../../components/Modal/BulkDelete';
import { loadOrganization } from '../../helpers/apis'
import { BiRefresh } from 'react-icons/bi';

const Candidates = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const location = useLocation()
    const org = new URLSearchParams(location.search).get('org');

    const statusA = [
        { value: 1, label: "Pending", option: 'pending' },
        { value: 2, label: "Interview", option: 'interview' },
        { value: 3, label: "Offered", option: 'offered' },
        { value: 4, label: "Accepted", option: 'accepted' },
        { value: 5, label: "Rejected", option: 'rejected' }
    ]
    const [showModal, setShowModal] = useState()
    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [filteredData, setFilteredData] = useState()
    const [select, setSelect] = useState(false)
    const [query, setQuery] = useState("")
    const candidateData = useRef()
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState()

    useEffect(() => {
        if (org) {
            setOrganization(organizationOptions?.find(data => data.value == org))
            setDATA(data?.filter(data => data?.Job_Opening?.organizationId == org))
        }
    }, [org, organizationOptions, data])



    async function loadCandidate() {
        let { status, data } = await get(
            CANDIDATE + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data)
            setDATA(data)
        }
    }
    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadCandidate()
    }, [])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        candidateData.current = selectedRows
    }

    const changeStatus = async (id, e) => {
        const value = { status: e.target.value }
        let { status, message } = await post(
            CANDIDATE + "/change/status/" + id,
            value,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status)
            showToast(status, message);
    }


    const bulksStatus = async (Status) => {
        const ids = candidateData.current.map(row => row.id)
        let { status, message } = await post(
            CANDIDATE + "/bulk/change/status",
            { status: Status, id: ids },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadCandidate()
        }
    }

    const handleSelect = (e) => {
        setSelectedOption(e)
        if (e)
            setFilteredData(Data.filter(dat => dat.status === e?.option))
        else
            setFilteredData()
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const EmailCell = (row) => {
        const isDuplicate = data.some(candidate =>
            candidate.email === row.email && candidate.id !== row.id
        );
        const emailContent = isDuplicate ?
            <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setQuery(row.email)}>{row.email} (duplicate)</span> :
            row.email;
        return <div>{emailContent}</div>;
    }

    const NumberCell = (row) => {
        const isDuplicate = data.some(candidate =>
            candidate.phoneNo === row.phoneNo && candidate.id !== row.id
        );
        const numberContent = isDuplicate ?
            <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setQuery(row.phoneNo)}>{row.phoneNo} (duplicate)</span> :
            row.phoneNo;
        return <div>{numberContent}</div>;
    };


    const columns = [
        {
            name: 'Name',
            selector: row => row.firstName,
            sortable: true,
            cell: row => <div>{row.firstName} {row.lastName}</div>
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            cell: row => EmailCell(row)
        },
        {
            name: 'Number',
            selector: row => row.phoneNo,
            sortable: true,
            cell: row => NumberCell(row)
        },
        {
            name: 'Department',
            selector: row => row.Job_Opening?.Department?.name,
            sortable: true
        },
        {
            name: 'Position',
            selector: row => row.Job_Opening?.Position?.name,
            sortable: true,
            cell: row => <Link to={`/JobOpenings/details/${row.Job_Opening?.id}`}>
                {row.Job_Opening?.Position?.name}
            </Link>
        },
        {
            name: 'Salary',
            selector: row => row.expectedSalary,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: true,
            cell: row => <select
                name="status"
                className="dropDown"
                defaultValue={row.status}
                onChange={(e) => {
                    changeStatus(row.id, e)
                }}
            >
                <option value="pending" className="dropdownOptions">Pending</option>
                <option value="interview" className="dropdownOptions">Interview</option>
                <option value="accepted" className="dropdownOptions">Accepted</option>
                <option value="rejected" className="dropdownOptions">Rejected</option>
            </select>
        },
        {
            name: "Action",
            selector: row => row.action,
            button: true,
            cell: (row) => <div
                className={classes.actionIcons}
            >
                <DeleteAction
                    Data={candidateData}
                    row={row}
                    setShowModal={setShowModal}
                />
                <Link to={`/candidate/info/${row.id}`} >
                    <GrContactInfo className="infoIcon" />
                </Link>

                <EditAction
                    link={"/candidate/AddEditCandidate"}
                    id={row.id}
                />
            </div>
        },

    ]

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.firstName.toLowerCase().includes(query) ||
            dat.email.toLowerCase().includes(query) ||
            dat.phoneNo.toString().includes(query)
        ))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.firstName.toLowerCase().includes(query) ||
                    dat.email.toLowerCase().includes(query) ||
                    dat.phoneNo.toString().includes(query)
                ))
        }
        else {
            const filtered = data.filter(data => data?.Job_Opening.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.firstName.toLowerCase().includes(query) ||
                    dat.email.toLowerCase().includes(query) ||
                    dat.phoneNo.toString().includes(query)
                ))
            setDATA(filtered)
        }
    };


    useEffect(() => {
        loadCandidate()
    }, [showModal])

    return <>
        <Card>
            <CardHeader>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Candidates View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Candidates</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardBody>

                <CreationButton link={"/candidate/AddEditCandidate"} module="Candidates" />
                <Button color="success" className='create' onClick={() => loadCandidate()}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                {select && <BulkDeleteButton
                    setShowModal={setShowModal}
                    module={"Candidates"}
                />}
                {select && <div className='candidateDropdown'>
                    <UncontrolledDropdown>
                        <DropdownToggle
                            caret
                            color="info"
                            data-toggle="dropdown"
                            type="button"
                        >
                            Select Action
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => bulksStatus('pending')}>Pending</DropdownItem>
                            <DropdownItem onClick={() => bulksStatus('interview')}>Interview</DropdownItem>
                            <DropdownItem onClick={() => bulksStatus('accepted')}>Accepted</DropdownItem>
                            <DropdownItem onClick={() => bulksStatus('rejected')}>Rejected</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                }

                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                {loggedUser?.user.userType === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <Select
                    options={statusA}
                    value={selectedOption}
                    isClearable={true}
                    placeholder="Filter Status"
                    onChange={handleSelect}
                    className={classes.filterDropDown}
                />

                <TableComponent
                    columns={columns}
                    Data={Data}
                    query={query || selectedOption}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                />

                <Delete
                    showModal={showModal === "delete"}
                    setShowModal={setShowModal}
                    DATA={candidateData.current}
                    type={"Department"}
                    module={CANDIDATE}
                />

                <BulkDelete
                    showModal={showModal === "Bulk-delete"}
                    setShowModal={setShowModal}
                    DATA={candidateData.current}
                    module={CANDIDATE}
                />
            </CardBody>
        </Card>
    </>
}

export default Candidates