import React, { useState } from 'react'
import classes from '../../assets/styles/login.module.css'
import { AiOutlineClose } from 'react-icons/ai'
import { Modal, ModalBody, Button } from 'reactstrap'
import { post } from '../../server'
import { USER } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import Loader from '../Global Components/Loader'

export const ForgotPassword = ({ modal, setModal }) => {
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState(false)

    async function ResetLink(e) {
        setLoading(true)
        e.preventDefault()
        let { status, message } = await post(
            USER + "/forget/password",
            { email }
        );
        if (status) {
            setLoading(false)
            showToast(status, message)
            if (status === 200)
                setModal("close")
        }
    }

    return <>
        <Modal isOpen={modal}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModal("close")}
                >
                    <AiOutlineClose className='tim-icons' />
                </button>
                <h4 className="title title-up">Enter Email to reset your password</h4>
            </div>
            <ModalBody className="text-center">
                <form onSubmit={ResetLink}>
                    <input
                        className={classes.login}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email'
                    />
                </form>
            </ModalBody>
            <div style={{ textAlign: '-webkit-center' }}>
                {!loading ? <Button
                    className={classes.button}
                    color="info"
                    onClick={ResetLink}>Send Email</Button>
                    :
                    <Loader />}
            </div>
        </Modal>
    </>

}