const Joi = require("joi");
const AddEditCandidateUserProfileSchema = Joi.object({
    id: Joi.allow(null),

    first_name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter First name`,
        "string.min": `First name must be minimum 1 characters!`,
        "string.max": `First name must be maximum 25 characters!`,
    }),

    last_name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Last name`,
        "string.min": `Last name must be minimum 1 characters!`,
        "string.max": `Last name must be maximum 25 characters!`,
    }),
    email: Joi.string()
        .max(50)
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": `Please enter Email Address`,
            "any.required": `Please enter valid email address`,
            "string.max": `Email must be maximum 50 characters!`,
        }),
    phone_no: Joi.number().min(8).required().messages({
        "number.empty": `Please enter your Phone Number`,
        "number.min": `Phone number must be 8 characters!`,
        "number.max": `Phone number must be maximum 13 characters`,
    }),
});

export default AddEditCandidateUserProfileSchema;
