const Joi = require("joi");
const EmployeeExperienceSchema = Joi.object({
    id: Joi.allow(null),

    //Experience
    company_name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Company Name`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),

    designation: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Designation`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),

    from_date: Joi.date().min('2000-01-01').max(Joi.ref('to_date')).required().messages({
        "date.min": `Starting Date must be on or after 2000-01-01`,
        "date.max": `Starting Date cannot be after Ending Date`,
        "date.empty": `Please select Starting Date`,
        "any.required": `Please select Starting Date`,
    }),
    to_date: Joi.date().required().messages({
        "date.empty": `Please select Ending Date`,
        "any.required": `Please select Ending Date`,
    }),

});

export default EmployeeExperienceSchema;
