import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { Modal, ModalBody, Button } from 'reactstrap'
import { post } from '../../server'
import { loggedInUser } from '../../utils/store'
import { useAtom } from "jotai";
import showToast from '../../helpers/showToast'

export const BulkDelete = ({ showModal, setShowModal, DATA, module }) => {
    const [loggedUser] = useAtom(loggedInUser);
    const [data, setData] = useState()
    useEffect(() => {
        if (showModal)
            if (DATA) {
                setData(DATA?.map(rows => rows.id))
            }
    }, [DATA, showModal])

    const bulkDelete = async () => {
        let { status, message } = await post(
            module + "/bulk/delete",
            { id: data },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200) {
                setShowModal("close")
            }
        }
    }

    return <>
        <Modal isOpen={showModal}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setShowModal("close")}
                >
                    <AiOutlineClose className='tim-icons' />
                </button>
                <h4 className="title title-up">Warning!!</h4>
            </div>
            <ModalBody className="text-center">
                <h3 className='Header'>Press confirm to delete the  selected {DATA?.length}
                    {DATA?.length === 1 ? " Row" : " Rows"}</h3>
            </ModalBody>
            <div style={{ textAlign: '-webkit-center' }}>
                <Button
                    className="delete"
                    color="danger"
                    onClick={bulkDelete}>DELETE</Button>
            </div>
        </Modal>

    </>

}