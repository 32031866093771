
import React from "react";
import classNames from "classnames";
import { useLocation, NavLink as LINK } from "react-router-dom";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  UncontrolledTooltip,
} from "reactstrap";

import { HiListBullet } from 'react-icons/hi2'
import { AiOutlineAlignCenter } from 'react-icons/ai'
import defaultPicture from '../assets/images/defaultProfile.png'
import showToast from "../helpers/showToast";
import { CgDarkMode } from 'react-icons/cg'

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const location = useLocation();

  const handleLogout = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('loggedUser')
    showToast("ok", "Logged Out")
  }
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal

  const handleTheme = () => {
    const themeButton = document.getElementById('theme');
    themeButton.addEventListener('click', () => {
      themeButton.classList.toggle('rotated');
      document.body.classList.toggle("white-content");
    });
  }
  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: location.pathname.indexOf("full-screen-map") === -1
        })}
        expand="lg"
      >
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
            <Button
              className="minimize-sidebar btn-just-icon"
              color="link"
              id="tooltip209599"
              onClick={props.handleMiniClick}
            >
              <AiOutlineAlignCenter className="visible-on-sidebar-regular navbarIcons" />
              <HiListBullet className="visible-on-sidebar-mini navbarIcons" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209599"
              placement="right"
            >
              Sidebar toggle
            </UncontrolledTooltip>
          </div>
          <div
            className={classNames("navbar-toggle d-inline", {
              toggled: props.sidebarOpened
            })}
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={props.toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
            {props.brandText}
          </NavbarBrand>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav className="ml-auto" navbar>
            <CgDarkMode className="theme" id="theme" onClick={handleTheme} />
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                nav
                onClick={(e) => e.preventDefault()}
              >
                <div className="photo">
                  <img alt="..." src={defaultPicture} />
                </div>
                <b className="caret d-none d-lg-block d-xl-block" />
                <p className="d-lg-none">Log out</p>
              </DropdownToggle>

              <DropdownMenu className="dropdown-navbar" end tag="ul">
                <NavLink tag="li">
                  <DropdownItem className="nav-item">Profile</DropdownItem>
                </NavLink>
                <NavLink tag="li">
                  <LINK to="/settings">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </LINK>
                </NavLink>
                {/* <NavLink tag="li">
                  <DropdownItem className="nav-item">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown
                        className="me-2"
                        direction="start"
                      >
                        <DropdownToggle
                          caret
                          color="#525f7f"
                          onClick={(e) => e.preventDefault()}
                          nav
                          data-toggle="dropdown"
                          style={{ color: '#525f7f', padding: 0 }}
                        >Theme</DropdownToggle>
                        <DropdownMenu className="dropdown-navbar" end tag="ul">
                          <NavLink tag="li">
                            <DropdownItem className="nav-item">Dark Theme Light Theme</DropdownItem>
                          </NavLink>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </DropdownItem>
                </NavLink> */}
                <DropdownItem divider tag="li" />
                <NavLink tag="li"  >
                  <LINK to="/login" >
                    <DropdownItem
                      className="nav-item danger" onClick={handleLogout}
                    >Log out</DropdownItem>
                  </LINK>
                </NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
            <li className="separator d-lg-none" />


          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
